import * as React from 'react';

import HistoryRow from 'Components/HistoryRow';
import HistoryRowControls from 'Components/HistoryRowControls';

import { formatPartialDate } from 'neuro-utils';
import { DoctorsOrdersContext } from '..';
import AdditionalDocuments from './AdditionalDocuments';
import RTMSHistory from './RTMS';
import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import OtherHistory from './Other';
import TDCSHistory from './TDCS';
import HistorySection from 'Components/HistorySection';
import DocumentCreationButton from 'Components/DocumentCreationButton';
import { Collapse, Theme, useMediaQuery } from '@mui/material';
import { findUserNames, getOrganizationUserData } from 'Routes/DoctorsOrders/utils';
import { showOtherFields } from '../utils';
import InputHandler from 'Components/InputHandler';
import { actions } from 'Store/documents/actions';
import { FormattedMessage } from 'react-intl';
import HistoryTabs from 'Components/HistoryTabs';
import { useAppDispatch } from 'Store/index';
import { isEmpty, isNil, omit } from 'Utility/ramdaReplacement';

const ConfirmationText = () => (
  <FormattedMessage
    id="doctorsOrders.infoText"
    values={{
      strong: (chunks) => <div style={{ fontWeight: '600' }}>{chunks}</div>,
    }}
  />
);

const ConditionalHistoryTabs = ({
  condition,
  children,
  startIndex,
}: {
  condition: boolean;
  children: React.ComponentProps<typeof HistoryTabs>['children'];
  startIndex: React.ComponentProps<typeof HistoryTabs>['startIndex'];
}): JSX.Element =>
  condition ? (
    <HistoryTabs startIndex={startIndex} disableZeroCounts>
      {children}
    </HistoryTabs>
  ) : (
    <React.Fragment>{children.map((c) => c.content)}</React.Fragment>
  );

const DoctorsOrdersHistory = ({ startEdit, mode = 'default' }: IDoctorsOrdersHistoryProps): JSX.Element => {
  const [sectionsOpen, setSectionsOpen] = React.useState<Array<number>>([0]);
  const [organizationUserData, setOrganizationUserData] = React.useState<Array<{ [key: string]: string | boolean }>>(
    [],
  );

  const doctorsOrdersContext = React.useContext(DoctorsOrdersContext);
  const { documents, additionalDocuments = [], fm } = doctorsOrdersContext;

  const uncompletedDoctorsOrders = documents.filter((d) => !d.completed);

  const dispatch = useAppDispatch();

  // Change value of 'completed' in given doctor's order
  const changeValueOfCompleted = (d: IDoctorsOrder) => {
    actions.modifyField({ name: 'doctorsOrders', id: d._id }, d, { completed: !d.completed })(dispatch);
  };

  React.useEffect(() => {
    getOrganizationUserData()
      .then((res) => {
        if (Array.isArray(res)) {
          const users = res;
          // TODO: filter out / separate inactive users (?)
          setOrganizationUserData(users);
        }
      })
      .catch(() => {
        return;
      });
    // If reviewing, all sections are open at first by default
    if (mode === 'review') {
      setSectionsOpen(documents.map((_, i) => i));
    }
  }, []);

  const treatmentTypes = (d: IDoctorsOrder): 'rtms' | 'tdcs' | 'both' | undefined => {
    if (!d || !Array.isArray(d.treatmentType) || d.treatmentType.length === 0) return undefined;
    if (d.treatmentType.includes('rtms') && d.treatmentType.includes('tdcs')) return 'both';
    if (d.treatmentType.includes('rtms')) return 'rtms';
    return 'tdcs';
  };

  const toggleSection = (index: number) => {
    if (sectionsOpen.includes(index)) {
      const newSections = [...sectionsOpen];
      newSections.splice(
        newSections.findIndex((i) => index === i),
        1,
      );
      setSectionsOpen(newSections);
      return;
    }
    setSectionsOpen(sectionsOpen.concat(index));
  };

  // Track page width and breakpoints
  const mdOrSmaller = useMediaQuery((theme: Theme) => theme.breakpoints.down(1401));

  return (
    <React.Fragment>
      {mode === 'default' && (
        <HistorySection
          headerText={
            <div style={{ display: 'flex', fontSize: '2.2rem', whiteSpace: 'normal' }}>
              {fm('doctorsOrders.additionalDocuments.header')}
            </div>
          }
          headerStyle={{ height: 'auto' }}
          headerContentStyle={{ alignItems: mdOrSmaller ? 'baseline' : 'center' }}
          hasHistoryRows={false}
          mode="noBar"
          newButton={
            <DocumentCreationButton
              name="doctorsOrdersAdditional"
              text="general.edit"
              onClick={startEdit(additionalDocuments?.[0] ?? {}, 'doctorsOrdersAdditional')}
              width={15}
              height={3}
              fontSize={16}
              disabled={!isNil(((additionalDocuments?.[0] ?? {}) as IControlProps)._lockedFor)}
              disabledTooltip={<span>{fm('general.documentLocked')}</span>}
            />
          }
        >
          <AdditionalDocuments d={additionalDocuments?.[0]} />
        </HistorySection>
      )}
      <ConditionalHistoryTabs
        condition={mode === 'default' && documents.length > 0}
        startIndex={uncompletedDoctorsOrders.length === 0 ? 0 : 1}
      >
        {
          (mode === 'default' ? [documents, uncompletedDoctorsOrders] : [documents]).map((d, i) => ({
            title: `doctorsOrders.${i === 0 ? 'all' : 'uncompleted'}Orders`,
            content: d.map((d, i) => (
              <div
                key={d._id}
                style={{
                  marginBottom: !sectionsOpen.includes(i) ? (mode === 'review' ? '-2rem' : '-4rem') : undefined,
                }}
              >
                <HistoryRow
                  headerText={
                    d.date
                      ? `${formatPartialDate(d.date)}${
                          treatmentTypes(d)
                            ? `\u00A0\u00A0${fm(`doctorsOrders.treatmentType.opts.${treatmentTypes(d)}`)}`
                            : ''
                        }`
                      : undefined
                  }
                  rowButton={
                    mode === 'default' ? (
                      <HistoryRowControls
                        document={d}
                        startEdit={startEdit}
                        editConfirmationText={<ConfirmationText />}
                        deleteConfirmationText={<ConfirmationText />}
                      />
                    ) : undefined
                  }
                  controlsMargin={false}
                  onClick={() => toggleSection(i)}
                  open={sectionsOpen.includes(i)}
                >
                  <React.Fragment>
                    <Collapse in={sectionsOpen.includes(i)} collapsedSize={0}>
                      <div style={{ cursor: 'default' }} onClick={() => ''}>
                        <HistoryRowVerticalItem
                          header={fm('doctorsOrders.orderer')}
                          value={d.ordererId ? findUserNames(d.ordererId, organizationUserData) : '-'}
                        />
                        <HistoryRowVerticalItem
                          header={fm('doctorsOrders.registrar')}
                          value={d.registrarId ? findUserNames(d.registrarId, organizationUserData) : '-'}
                        />

                        <div style={{ marginBottom: '4.5rem' }}>
                          {treatmentTypes(d) && (
                            <React.Fragment>
                              {d.treatmentType?.includes('rtms') &&
                                (mode === 'default' || !isEmpty(omit(['createDate'], d.rtms ?? {}))) && (
                                  <RTMSHistory d={d} />
                                )}
                              {d.treatmentType?.includes('tdcs') &&
                                (mode === 'default' || !isEmpty(omit(['createDate'], d.tdcs ?? {}))) && (
                                  <TDCSHistory d={d} />
                                )}
                            </React.Fragment>
                          )}
                          {showOtherFields(d) ? <OtherHistory d={d} /> : undefined}
                        </div>
                      </div>
                    </Collapse>
                    <InputHandler
                      type="CheckboxSingle"
                      name="completed"
                      editing={true}
                      formData={{
                        onChange: () => changeValueOfCompleted(d),
                        document: d,
                      }}
                      option="true"
                      optionFormatter={() => fm('doctorsOrders.completed.title')}
                      showLabel
                    />
                  </React.Fragment>
                </HistoryRow>
              </div>
            )),
            count: d.length,
          })) as []
        }
      </ConditionalHistoryTabs>
    </React.Fragment>
  );
};

interface IDoctorsOrdersHistoryProps {
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
  view?: IView;
  mode?: 'default' | 'review';
}

export default DoctorsOrdersHistory;
