import * as React from 'react';

import { dissoc, evolve, includes, indexOf, values } from 'ramda';

import ActionButton from '../../../../../../../components/ActionButton';
import FormRow from '../../../../../../../components/FormRow';
import { Container, Item } from '../../../../../../../components/Grid';

import { sortStrengths } from '../../../../../../../utility/randomUtil';

import Program from './Program';

import { formatStrenghtString } from '../utils';
import InputHandler from '../../../../../../../components/InputHandler';
import MyHooks from '../../../../../../../utility/customHooks';
import Doses from './Doses';
import { IRegimenContext } from '..';
import { exists } from 'neuro-utils';
import { FormattedMessage } from 'react-intl';
import UnitSelect from '../components/UnitSelect';
import DosingInstructions from '../components/DosingInstructions';
import { createID } from '../../../../../../../utility/appendIDs';
import { isNil, uniq } from 'Utility/ramdaReplacement';

// Generate strengths array for current medication from available packages
const strengthsArray = (packages: IMedicationPackage[]): { [key: string]: string }[] => {
  const strengths = packages.map((p: IMedicationPackage) => p.strengths);
  return uniq(strengths).sort((a1, a2) => sortStrengths(a1, a2));
};

const strengthOptionFormatter =
  (strArr: IStrengths[]) =>
  (index: number | string): string =>
    formatStrenghtString(strArr[index as number]);

const Default = ({ formData, editIndex, packages }: IRegimenContext): JSX.Element => {
  const { document, onChange } = formData;

  const [showProgram, setShowProgram] = React.useState<boolean>(false);

  const getRegimens = React.useCallback((doc: IMedication) => (doc as IMedication).regimen?.map((r) => r), []);

  const thisRegimen = React.useMemo(
    () => (getRegimens(document)?.[editIndex] || {}) as IRegimenDefault,
    [document, editIndex, getRegimens],
  );

  const availableStrengths =
    document.isClinicalStudy && thisRegimen ? thisRegimen?.strengths ?? [] : strengthsArray(packages);

  const getStrengths = (regimen: IRegimenDefault): Array<number> =>
    regimen.strengths
      ? uniq(
          regimen.strengths
            .map((d: IStrengths) => indexOf(d, availableStrengths))
            .sort((a: number, b: number) => a - b),
        )
      : [];

  const getStrengthIndex = (s: number): string => {
    const strength = availableStrengths[s];
    const idx = values(strength).join('/');
    return idx;
  };

  const strengthsSelector = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name] as string;

    if (Array.isArray(value)) {
      const regimenArr = getRegimens(document) as IRegimenDefault[];
      if (regimenArr) {
        const sortedValues = value.sort((a, b) => a - b);
        const strengths = getStrengths(thisRegimen).sort((a, b) => a - b);

        // Add new strength
        if (sortedValues.length > strengths.length) {
          const newStrVal = sortedValues.find((i) => !includes(i, strengths));

          const modifiedStrengths = [...(regimenArr[editIndex].strengths || []), availableStrengths[newStrVal]];

          regimenArr[editIndex] = {
            ...regimenArr[editIndex],
            strengths: modifiedStrengths,
          };
        }

        // Remove exiting strengths option
        if (strengths.length > sortedValues.length) {
          let modifiedStrengths: IStrengths[] = [];
          const removedStr = strengths.find((i) => !includes(i, sortedValues));

          if (sortedValues.length > 0) {
            modifiedStrengths = sortedValues.map((s) => availableStrengths[s]);
          }

          const dosages = regimenArr[editIndex].dosages || [];
          const updateStrengths = (d: IDosage['dosages']): IDosage['dosages'] =>
            removedStr && exists(removedStr) && d ? dissoc<typeof d, string>(getStrengthIndex(removedStr), d) : d;

          regimenArr[editIndex] = {
            ...regimenArr[editIndex],
            strengths: modifiedStrengths,
            dosages: dosages.map((d) => evolve({ dosages: updateStrengths }, d)),
          };
        }
        onChange?.({ regimen: regimenArr });
      }
    }
  };

  const changeValue =
    (name: string) =>
    (values: TOnChangeValues): void => {
      let value = values[name];
      if (!isNil(value)) {
        const regimen = getRegimens(document);
        if (regimen) {
          if (name === 'strengths') {
            const newStrengths = JSON.parse(JSON.stringify(regimen[editIndex].strengths ?? []));
            const strengthFormatted = { [document.medicationSubstances ?? 's']: value.toString() };
            if (newStrengths.length > 0) {
              newStrengths[0] = strengthFormatted;
            } else {
              newStrengths.push(strengthFormatted);
            }
            value = newStrengths;
          }
          regimen[editIndex] = { ...regimen[editIndex], [name]: value } as IRegimenBasics & IRegimenDefault;
          if (
            document.isClinicalStudy &&
            Array.isArray((regimen[editIndex] as IRegimenBasics & IRegimenDefault).dosages)
          ) {
            regimen[editIndex] = { ...regimen[editIndex], dosages: [{ id: createID() }] } as IRegimenBasics &
              IRegimenDefault;
          }
          onChange?.({ regimen: regimen });
        }
      }
    };
  const mountRoutine = React.useCallback(() => {
    if (!showProgram && thisRegimen?.dosages && thisRegimen?.dosages?.length > 0) setShowProgram(true);
  }, [showProgram, thisRegimen]);

  MyHooks.useWhenMounted(mountRoutine, [mountRoutine]);

  const InlineItems = (props: { children: Array<JSX.Element> }): JSX.Element | null => {
    if (!props.children) return null;
    return (
      <Container style={{ width: '40rem' }}>
        {props.children.map((child, idx) => (
          <Item key={`inline-item-${idx}`} xs={idx === 0 ? 1 : 4}>
            {child}
          </Item>
        ))}
      </Container>
    );
  };

  const showProgramFunction = (open: boolean): void => {
    if (open && (!thisRegimen.dosages || thisRegimen.dosages?.length === 0)) {
      changeValue('dosages')({ dosages: [{ id: createID() }] });
    }
    setShowProgram(open);
  };

  return (
    <React.Fragment>
      {!document.isClinicalStudy && (
        <FormRow title="medication.substances" headerWidth={3}>
          <Container>
            <Item xs={3}>
              {document.medicationSubstances && document.medicationSubstances.length > 0
                ? document.medicationSubstances
                : '-'}
            </Item>
          </Container>
        </FormRow>
      )}

      <FormRow
        title={`medication.${!document.isClinicalStudy ? 'regimenStrengths' : 'strength'}`}
        description={!document.isClinicalStudy ? <FormattedMessage id="medication.regimenStrengthsInfo" /> : undefined}
        headerWidth={3}
      >
        {document.isClinicalStudy ? (
          <InputHandler
            type="TextField"
            name="strengths"
            editing={true}
            formData={{
              document: { strengths: thisRegimen.strengths?.[0]?.[document.medicationSubstances ?? 's'] },
              onChange: changeValue('strengths'),
            }}
            placeholder="medication.strength"
          />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'inline-flex' }}>
              <InputHandler
                type="MultiSelect"
                name="strengths"
                editing={true}
                formData={{
                  document: { strengths: getStrengths(thisRegimen) },
                  onChange: strengthsSelector,
                }}
                options={availableStrengths.map((_, i) => i).concat([])}
                optionFormatter={strengthOptionFormatter(availableStrengths)}
                autoSelectSingle={true}
              />
            </div>
            <div style={{ display: 'inline-flex' }}>
              <DosingInstructions medication={document} />
            </div>
          </div>
        )}
      </FormRow>

      <UnitSelect unitValue={thisRegimen.unit} onChange={changeValue('unit')} />

      <FormRow title="medication.programRegimenDosage" headerWidth={3}>
        <ActionButton
          text={`medication.${showProgram ? 'hideProgram' : 'showProgram'}`}
          onClick={(): void => showProgramFunction(!showProgram)}
          width={16}
          height={3}
          fontSize={16}
        />
      </FormRow>

      {showProgram ? (
        <>
          <FormRow headerWidth={3}>
            <Program formData={formData} editIndex={editIndex} availableStrengths={availableStrengths} />
          </FormRow>
          <FormRow title="medication.doses.period" headerWidth={3}>
            <InlineItems>
              <FormattedMessage id="medication.doses.every" />
              <InputHandler
                type="NumberField"
                name="periodNumber"
                editing={true}
                width={10}
                formData={{
                  document: { periodNumber: thisRegimen.periodNumber },
                  onChange: changeValue('periodNumber'),
                }}
                placeholder={'medication.doses.enterNumber'}
                precision={2}
              />
              <InputHandler
                type="Select"
                name="periodUnit"
                editing={true}
                width={15}
                formData={{
                  document: { periodUnit: thisRegimen.periodUnit },
                  onChange: changeValue('periodUnit'),
                }}
                options={['h', 'd', 'w']}
                placeholder={'medication.chooseUnit'}
                optionFormatter={(s: string | number): JSX.Element => (
                  <FormattedMessage id={`medication.doses.periodOpts.${s}`} />
                )}
              />
            </InlineItems>
          </FormRow>
        </>
      ) : (
        <Doses formData={formData} editIndex={editIndex} />
      )}
    </React.Fragment>
  );
};

export default Default;
