import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistorySection from '../../../components/HistorySection';
import HistoryRow from '../../../components/HistoryRow';
import HistoryRowControls from '../../../components/HistoryRowControls';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import HistoryRowData from './HistoryRowData';
import Form from './Form';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import { isEnded } from '../utils';
import DocumentCreationButton from '../../../components/DocumentCreationButton';

const ongoingSymptoms = (
  documents: IOtherSymptoms[],
  startEdit: (document: TAnyObject) => (e: React.MouseEvent) => void,
): JSX.Element | undefined => {
  return documents.length > 0 ? (
    <HistorySection headerText={<FormattedMessage id="otherSymptoms.ongoingSymptoms" />} hasHistoryRows={true}>
      {documents
        .sort((n1, n2) => n1._cdate - n2._cdate)
        .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
        .reverse()
        .map((d: IOtherSymptoms, i: number) => (
          <HistoryRow
            headerText={
              d.date ? (
                <FormattedMessage id="otherSymptoms.startingDate" values={{ date: formatPartialDate(d.date) }} />
              ) : undefined
            }
            key={i + 'active'}
            rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
            controlsMargin={false}
          >
            <HistoryRowData document={d} />
          </HistoryRow>
        ))}
    </HistorySection>
  ) : undefined;
};

const disappearedSymptoms = (
  documents: IOtherSymptoms[],
  startEdit: (document: TAnyObject) => (e: React.MouseEvent) => void,
): JSX.Element | undefined => {
  return documents.length > 0 ? (
    <HistorySection
      headerText={<FormattedMessage id="otherSymptoms.disappearedSymptoms" />}
      inactive={true}
      hasHistoryRows={true}
    >
      {documents
        .sort((n1, n2) => n1._cdate - n2._cdate)
        .sort((n1, n2) => sortPartialDate(n1.endDate, n2.endDate))
        .reverse()
        .map((d: IOtherSymptoms, i: number) => (
          <HistoryRow
            headerText={
              d.date ? formatPartialDate(d.date) + ' - ' + (d.endDate ? formatPartialDate(d.endDate) : '') : undefined
            }
            key={i + 'active'}
            rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
            controlsMargin={false}
          >
            <HistoryRowData document={d} />
          </HistoryRow>
        ))}
    </HistorySection>
  ) : undefined;
};

const OtherSymptoms = ({ documents }: IOwnProps): JSX.Element => (
  <FormEditingHandler name="otherSymptoms" documents={documents}>
    {(editing, startEdit, formData): JSX.Element => (
      <DocumentWrapper>
        <DocumentHeader
          name="otherSymptoms"
          headerId={'otherSymptoms.title'}
          editing={editing}
          editButtons={
            <div>
              <DocumentCreationButton name="otherSymptoms" text={'general.new'} onClick={startEdit({})} />
            </div>
          }
        />
        {!editing && documents ? (
          <div>
            {ongoingSymptoms(
              documents.filter((d: IOtherSymptoms) => !isEnded(d.endDate)),
              startEdit,
            )}
            {disappearedSymptoms(
              documents.filter((d: IOtherSymptoms) => isEnded(d.endDate)),
              startEdit,
            )}
          </div>
        ) : undefined}
        {editing ? (
          <Form
            formData={formData}
            editing={editing}
            document={documents && documents.find((d: IOtherSymptoms) => d._id === editing)}
          />
        ) : undefined}
      </DocumentWrapper>
    )}
  </FormEditingHandler>
);

interface IOwnProps {
  documents?: IOtherSymptoms[];
}

export default OtherSymptoms;
