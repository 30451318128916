import FormRow from 'Components/FormRow';
import TabContent from 'Components/TabContent';
import { equals, pick, remove } from 'ramda';
import * as React from 'react';
import { DoctorsOrdersContext } from 'Routes/DoctorsOrders/Document';
import { v4 } from 'uuid';
import { styled } from '@mui/system';
import ButtonRow from '../../Components/ButtonRow';
import TabEditor from '../../Components/TabEditor';
import Treatment from '../Treatment';
import {
  findAndCategorizePreviousSubjectsOfTreatmentsIds,
  getPreviousDocWithTreatments,
} from 'Routes/DoctorsOrders/utils';
import { createSubjectOfTreatmentNameObj as createSOTNameObj, subjectOfTreatmentTitle } from 'Utility/ninmtUtil';
import AddButtonContent from '../../Components/AddTabEditor';
import { sortPartialDate } from 'neuro-utils';
import colors from '../../../../../../config/theme/colors';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { AddCircle } from '@mui/icons-material';
import { isEmpty } from 'Utility/ramdaReplacement';

const StyledNoTabs = styled('div')({
  padding: '2rem 2rem 2rem 2rem',
  marginBottom: '2.5rem',
  border: `1px solid ${colors.gray}`,
  backgroundColor: colors.white,
});

const StyledListItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.5rem 1rem 0.5rem 1rem',
  color: colors.darkGray,
});

const StyledAddButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }: { disabled: boolean }) => ({
  display: 'flex',
  flexDirection: 'row' as const,
  justifyContent: 'space-evenly',
  padding: '0rem 2rem 0rem 0rem',
  fontWeight: 600,
  color: disabled ? colors.darkGray : colors.primary,
  userSelect: 'none' as const,
  '&:hover': {
    cursor: disabled ? 'default' : 'pointer',
  },
}));

const AddButton = ({ text, disabled }: { text?: string; disabled?: boolean }): JSX.Element => {
  return (
    <StyledAddButton disabled={!!disabled}>
      {text && <span style={{ marginRight: '0.7rem' }}>{text}</span>}
      <AddCircle />
    </StyledAddButton>
  );
};

const Treatments = ({ formData }: { formData: IFormData<IDoctorsOrder> }): JSX.Element => {
  const [treatmentsToAdd, setTreatmentsToAdd] = React.useState<{
    subjectOfTreatment: Array<string>;
    backupSubjectOfTreatment: Array<string>;
  }>({
    subjectOfTreatment: [],
    backupSubjectOfTreatment: [],
  });

  // Keep track of tabEditor index to navigate back from addTabEditor
  const [previousIndex, setPreviousIndex] = React.useState<number | undefined>(undefined);

  const { document, onChange } = formData;

  const doctorsOrdersContext = React.useContext(DoctorsOrdersContext);

  const { fm, documents, ninmtTreatmentPeriods, isEditing } = doctorsOrdersContext;

  /**
   * Option array for selecting previous treatments
   */
  const previousTreatmentIdsByCategory = (treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment') => {
    let doctorsOrdersDocs: Array<IDoctorsOrder> = [];

    if (Array.isArray(ninmtTreatmentPeriods) && ninmtTreatmentPeriods.length > 0) {
      const latestTreatmentPeriodDate = ninmtTreatmentPeriods.sort((n1, n2) => sortPartialDate(n2.date, n1.date))[0]
        .date;

      doctorsOrdersDocs = documents.filter((d) => sortPartialDate(d.date, latestTreatmentPeriodDate) >= 0);
    }

    return findAndCategorizePreviousSubjectsOfTreatmentsIds(
      doctorsOrdersDocs,
      'rtms',
      treatmentName === 'subjectOfTreatment' ? 'both' : treatmentName,
      [],
      fm,
    );
  };

  const subjectsOfTreatment: Array<IRTMSTreatment> =
    document.rtms &&
    Array.isArray(document.rtms.subjectOfTreatment) &&
    document.rtms.subjectOfTreatment.filter((s) => s).length > 0
      ? document.rtms.subjectOfTreatment
      : previousTreatmentIdsByCategory('subjectOfTreatment').length === 0
        ? [{ id: v4(), name: '' }]
        : [];

  const numberOfSubjectsOfTreatment =
    document.rtms && Array.isArray(document.rtms.subjectOfTreatment)
      ? document.rtms.subjectOfTreatment.filter((s) => s).length
      : 0;

  const backupSubjectsOfTreatment: Array<IRTMSTreatment> =
    document.rtms &&
    Array.isArray(document.rtms.backupSubjectOfTreatment) &&
    document.rtms.backupSubjectOfTreatment.filter((s) => s).length > 0
      ? document.rtms.backupSubjectOfTreatment
      : previousTreatmentIdsByCategory('backupSubjectOfTreatment').length === 0
        ? [{ id: v4(), name: '' }]
        : [];

  const numberOfBackupSubjectsOfTreatment =
    document.rtms && Array.isArray(document.rtms.backupSubjectOfTreatment)
      ? document.rtms.backupSubjectOfTreatment.filter((s) => s).length
      : 0;

  const previousDocWithSOT = getPreviousDocWithTreatments(documents, 'rtms', 'subjectOfTreatment');
  const previousDocWithBSOT = getPreviousDocWithTreatments(documents, 'rtms', 'backupSubjectOfTreatment');

  let previousTreatments: Array<IRTMSTreatment> = [];
  let previousBackupTreatments: Array<IRTMSTreatment> = [];

  if (previousDocWithSOT) {
    previousTreatments = previousDocWithSOT.rtms?.subjectOfTreatment as typeof previousTreatments;
  }
  if (previousDocWithBSOT) {
    previousBackupTreatments = (previousDocWithBSOT.rtms?.backupSubjectOfTreatment ?? []).map((s) =>
      pick(['id', 'name', 'nameOther', 'specifier'], s),
    );
  }

  const allPreviousTreatments = [...previousTreatments, ...previousBackupTreatments];

  const treatmentOnChange =
    (index: number, treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment') =>
    (value: TOnChangeValues): void => {
      const treatments = document.rtms && document.rtms[treatmentName];
      // Check if there exists an treatment array with given index
      if (Array.isArray(treatments) && treatments.length >= index) {
        const treatmentsClone = [...treatments];
        treatmentsClone[index] = Object.assign({}, treatmentsClone[index], value);
        onChange && onChange({ rtms: { ...document.rtms, [treatmentName]: treatmentsClone } });
      } else {
        // Treatment array does not exists
        onChange &&
          onChange({
            rtms: {
              ...document.rtms,
              [treatmentName]: [Object.assign({ id: v4(), name: '' }, value)],
            },
          });
      }
    };

  /**
   * Change values of array type (currently pulseIntensity and numberOfPulses)
   */
  const treatmentOnChangeArray =
    (treatment: 'subjectOfTreatment' | 'backupSubjectOfTreatment', treatmentIndex: number) =>
    (i: number, k: 'pulseIntensity' | 'numberOfPulses', initial: Array<null | number>) =>
    (value: TOnChangeValues): void => {
      const val = value[k];
      const treatments: Array<IRTMSTreatment> = (document.rtms && document.rtms[treatment]) ?? [];
      const treatmentsClone = JSON.parse(JSON.stringify(treatments));
      let thisTreatment = treatmentsClone[treatmentIndex] ?? {};
      const thisField = thisTreatment[k] ?? initial;
      if (typeof val === 'number' || val === null) thisField[i] = val;
      thisTreatment = Object.assign({}, thisTreatment, { [k]: thisField });
      treatmentsClone[treatmentIndex] = thisTreatment;
      onChange && onChange({ rtms: { ...document.rtms, [treatment]: treatmentsClone } });
    };

  /**
   * Change selected previous treatments in addTabEditor
   */
  const treatmentsToAddOnChange =
    (treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment') =>
    (val: TFieldValue): void => {
      type TFieldValueArrayTypes = string | number | boolean | object | undefined;
      if (
        Array.isArray(val) &&
        (val as TFieldValueArrayTypes[]).every((v: TFieldValueArrayTypes) => typeof v === 'string')
      ) {
        setTreatmentsToAdd({ ...treatmentsToAdd, [treatmentName]: val as Array<string> });
      }
    };

  /**
   * Add new subjects of treatment and copy previous ones
   * @param {boolean} simple - used to override functionality related to copying previous treatments
   */
  const treatmentAddEvents = (treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment', simple?: boolean) => {
    const rtms = JSON.parse(JSON.stringify(document.rtms));
    const thisSubjectOfTreatment = rtms && rtms[treatmentName];
    return (changeSelectedTab?: (i: number) => () => void) => (): void => {
      const newTreatments = allPreviousTreatments.filter((t) => treatmentsToAdd[treatmentName].includes(t.id));
      if (treatmentsToAdd[treatmentName].includes('new') || simple) {
        newTreatments.push({ id: v4(), name: '' });
      }
      if ((!Array.isArray(thisSubjectOfTreatment) || thisSubjectOfTreatment.length < 4) && newTreatments.length > 0) {
        // Check if there exists treatment array
        if (Array.isArray(thisSubjectOfTreatment) && thisSubjectOfTreatment.length > 0) {
          // If there is an array and it contains treatment
          if (thisSubjectOfTreatment.length > 0 && thisSubjectOfTreatment.length < 4) {
            newTreatments.forEach((newTreatment) => {
              if (treatmentName === 'subjectOfTreatment') thisSubjectOfTreatment.push({ ...newTreatment, id: v4() });
              // For backup subjects of treatment, new events are added to the top (= start of treatments array)
              else thisSubjectOfTreatment.unshift({ ...newTreatment, id: v4() });
            });
          }
          if (onChange) {
            const updateValues = { [treatmentName]: thisSubjectOfTreatment };
            // If copying from backup subjects of treatment, remove from backup subjects of treatment after copying
            if (treatmentName === 'subjectOfTreatment' && Array.isArray(document?.rtms?.backupSubjectOfTreatment)) {
              updateValues.backupSubjectOfTreatment =
                document?.rtms?.backupSubjectOfTreatment.filter(
                  (s) => !newTreatments.some((t) => equals(createSOTNameObj(s), createSOTNameObj(t))),
                ) ?? [];
            }
            onChange({ rtms: { ...document.rtms, ...updateValues } });
            changeSelectedTab && changeSelectedTab(thisSubjectOfTreatment.length - 1)();
            // For backup subjects of treatment, treatmentsToAdd array is emptied separately
            if (treatmentName === 'subjectOfTreatment') setTreatmentsToAdd({ ...treatmentsToAdd, [treatmentName]: [] });
          }
        } else {
          // If there is no treatment array and user still wants to add another treatment
          if (onChange) {
            const updateValues = { [treatmentName]: newTreatments.map((t) => ({ ...t, id: v4() })) };
            // If copying from backup subjects of treatment, remove from backup subjects of treatment after copying
            if (treatmentName === 'subjectOfTreatment' && Array.isArray(document?.rtms?.backupSubjectOfTreatment)) {
              updateValues.backupSubjectOfTreatment =
                document?.rtms?.backupSubjectOfTreatment.filter(
                  (s) => !newTreatments.some((t) => equals(createSOTNameObj(s), createSOTNameObj(t))),
                ) ?? [];
            }
            onChange({ rtms: { ...document.rtms, ...updateValues } });
            changeSelectedTab && changeSelectedTab(newTreatments.length - 1)();
            // For backup subjects of treatment, treatmentsToAdd array is emptied separately
            if (treatmentName === 'subjectOfTreatment') setTreatmentsToAdd({ ...treatmentsToAdd, [treatmentName]: [] });
          }
        }
      }
    };
  };

  /**
   * Cancel adding new subjects of treatment
   */
  const treatmentCancelEvent =
    (treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment') =>
    (changeSelectedTab?: (i: number) => () => void) => {
      return () => {
        changeSelectedTab && changeSelectedTab(previousIndex ?? 0)();
        setTreatmentsToAdd({ ...treatmentsToAdd, [treatmentName]: [] });
      };
    };

  /**
   * Remove subject of treatment
   */
  const treatmentRemoveEvent =
    (treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment') =>
    (selectedTabIndex: number, changeSelectedTab?: (i: number) => () => void) => {
      let thisSubjectOfTreatment = document.rtms && document.rtms[treatmentName];
      return () => {
        if (Array.isArray(thisSubjectOfTreatment) && thisSubjectOfTreatment.length >= selectedTabIndex) {
          thisSubjectOfTreatment = remove<IRTMSTreatment>(selectedTabIndex, 1, thisSubjectOfTreatment);
          onChange && onChange({ rtms: { ...document.rtms, [treatmentName]: thisSubjectOfTreatment } });
          if (changeSelectedTab && !thisSubjectOfTreatment?.[selectedTabIndex]) {
            const newIndex = thisSubjectOfTreatment ? thisSubjectOfTreatment.length - 1 : 0;
            changeSelectedTab(newIndex)();
          }
        }
      };
    };

  /**
   * Option formatter for treatment names in previous treatments menu
   */
  const treatmentNameOptionFormatter = (name: string | number) => {
    const t = allPreviousTreatments.find((t) => t.id === name);
    return t && t?.name
      ? `${t.name === 'other' && t.nameOther ? t.nameOther : fm(`doctorsOrders.subjectOfTreatmentNames.${t.name}`)}${
          t.specifier ? ' ' + t.specifier : ''
        }`
      : '-';
  };

  /**
   * Treatments already in use / copied to be filtered out from available options
   */
  const findPreviousTreatmentsInUse = (
    treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment',
  ): Array<IRTMSTreatment> => {
    const previous = allPreviousTreatments;
    const currentSOT = treatmentName === 'subjectOfTreatment' ? document.rtms?.subjectOfTreatment ?? [] : [];
    const currentBSOT =
      treatmentName === 'backupSubjectOfTreatment' ? document.rtms?.backupSubjectOfTreatment ?? [] : [];
    const currentAll = [...currentSOT, ...currentBSOT];
    const filtered = previous.filter((t) => {
      if (currentAll.some((c) => equals(createSOTNameObj(c), createSOTNameObj(t)))) return true;
      return false;
    });
    return filtered;
  };

  // Automatically copy backup subjects of treatment on mount (if they exist)
  React.useEffect(() => {
    const prev = previousTreatmentIdsByCategory('backupSubjectOfTreatment');
    if (!document.rtms?.backupSubjectOfTreatment && Array.isArray(prev) && prev[0] && prev[0].values) {
      const prevToCopy = prev[0].values.filter(
        (v) =>
          !findPreviousTreatmentsInUse('backupSubjectOfTreatment')
            .map((t) => t.id)
            .includes(v),
      );

      prevToCopy.forEach((id) => treatmentsToAdd.backupSubjectOfTreatment.push(id));
      treatmentAddEvents('backupSubjectOfTreatment')()();
      setTreatmentsToAdd({ ...treatmentsToAdd, backupSubjectOfTreatment: [] });
    }
  }, []);

  return (
    <React.Fragment>
      {['subjectOfTreatment', 'backupSubjectOfTreatment'].map((name, index) => {
        const treatmentName = name as 'subjectOfTreatment' | 'backupSubjectOfTreatment';
        const numberOfTr =
          treatmentName === 'subjectOfTreatment' ? numberOfSubjectsOfTreatment : numberOfBackupSubjectsOfTreatment;

        const previousBackupSubjectsOfTreatment = backupSubjectsOfTreatment.slice(
          treatmentsToAdd[treatmentName].includes('new') ? 1 : 0,
        );

        const showPreviousBackupSubjectsOfTreatment =
          previousBackupSubjectsOfTreatment.length > 0 &&
          (previousBackupSubjectsOfTreatment.length !== 1 || !isEmpty(previousBackupSubjectsOfTreatment[0].name));

        return (
          <FormRow key={index} title={`doctorsOrders.rtms.${treatmentName}.title`}>
            {
              {
                subjectOfTreatment:
                  isEditing || numberOfTr > 0 ? (
                    <TabContent
                      tabEditor={
                        <TabEditor
                          formData={formData}
                          treatmentType="rtms"
                          treatmentName={treatmentName}
                          allPreviousTreatments={allPreviousTreatments}
                          setPreviousIndex={setPreviousIndex}
                        />
                      }
                      tabButtonRow={
                        isEditing && numberOfTr > 1 ? (
                          <ButtonRow type="remove" remove={treatmentRemoveEvent(treatmentName)} />
                        ) : undefined
                      }
                      addTabEditor={
                        isEditing && numberOfTr < 4
                          ? {
                              id: 'addTabEditor',
                              title: '',
                              content: (
                                <AddButtonContent
                                  title={fm('doctorsOrders.rtms.treatments.selectTreatment')}
                                  options={previousTreatmentIdsByCategory(treatmentName)}
                                  optionFormatter={treatmentNameOptionFormatter}
                                  onChange={treatmentsToAddOnChange(treatmentName)}
                                  selectedOptions={treatmentsToAdd[treatmentName]}
                                  disabledOptions={findPreviousTreatmentsInUse(treatmentName).map((t) => t.id)}
                                />
                              ),
                              count: numberOfTr + 1,
                            }
                          : undefined
                      }
                      addTabButtonRow={
                        <ButtonRow
                          type={numberOfTr > 0 ? 'add' : 'select'}
                          add={treatmentAddEvents(treatmentName)}
                          cancel={treatmentCancelEvent(treatmentName)}
                          disabled={
                            treatmentsToAdd[treatmentName].length === 0 ||
                            treatmentsToAdd[treatmentName].length + numberOfTr > 4
                          }
                          disabledTooltip={
                            numberOfTr > 0 ? (
                              treatmentsToAdd[treatmentName].length + numberOfTr > 4 ? (
                                <span>
                                  {fm('doctorsOrders.addingMoreSubjectsOfTreatmentDisabledTooltip', {
                                    N: `${4 - numberOfTr}`,
                                  })}
                                </span>
                              ) : undefined
                            ) : treatmentsToAdd[treatmentName].length + numberOfTr > 4 ? (
                              <span>{fm('doctorsOrders.selectingMoreSubjectsOfTreatmentDisabledTooltip')}</span>
                            ) : undefined
                          }
                          text={numberOfTr > 0 ? undefined : 'general.choose'}
                        />
                      }
                      customAddTabEditorHandler={
                        !Array.isArray(previousTreatmentIdsByCategory(treatmentName)) ||
                        previousTreatmentIdsByCategory(treatmentName).length === 0
                          ? treatmentAddEvents(treatmentName, true)
                          : undefined
                      }
                    >
                      {subjectsOfTreatment.map((s: IRTMSTreatment, i: number) => ({
                        id: s.id,
                        title: `${
                          typeof s.name === 'string' && s.name.length > 0 && s.name !== 'other'
                            ? fm(`doctorsOrders.subjectOfTreatmentNames.${s.name}`)
                            : s.name === 'other' && s.nameOther
                              ? s.nameOther
                              : fm('doctorsOrders.rtms.treatments.treatmentTitlePlaceholder')
                        }${s.specifier ? ' ' + s.specifier : ''}`,
                        content: (
                          <Treatment
                            treatmentName={treatmentName}
                            subjectOfTreatmentName={s.name}
                            document={s}
                            onChange={treatmentOnChange(i, treatmentName)}
                            onChangeArray={treatmentOnChangeArray(treatmentName, i)}
                          />
                        ),
                        count: i,
                      }))}
                    </TabContent>
                  ) : (
                    <span style={{ fontWeight: 600 }}>{'-'}</span>
                  ),
                backupSubjectOfTreatment: (
                  <React.Fragment>
                    {treatmentsToAdd[treatmentName].includes('new') ? (
                      <StyledNoTabs>
                        <div style={{ fontWeight: 600, color: colors.primary }}>
                          {fm('doctorsOrders.rtms.backupSubjectOfTreatment.new')}
                        </div>
                        <div style={{ padding: '1.5rem 0 3.5rem 0' }}>
                          <TabEditor
                            formData={formData}
                            treatmentType="rtms"
                            treatmentName={treatmentName}
                            allPreviousTreatments={allPreviousTreatments}
                            setPreviousIndex={setPreviousIndex}
                          />
                        </div>
                        <ButtonRow
                          type="add"
                          add={() => () => treatmentsToAddOnChange(treatmentName)([])}
                          cancel={() => () => {
                            treatmentRemoveEvent(treatmentName)(0)();
                            treatmentsToAddOnChange(treatmentName)([]);
                          }}
                          text="general.save"
                          disabled={isEmpty(backupSubjectsOfTreatment[0]?.name)}
                        />
                      </StyledNoTabs>
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div
                          style={{
                            visibility: showPreviousBackupSubjectsOfTreatment ? 'hidden' : undefined,
                            fontWeight: 600,
                            color: colors.darkGray,
                          }}
                        >
                          {fm('doctorsOrders.rtms.backupSubjectOfTreatment.none')}
                        </div>
                        <div
                          onClick={
                            numberOfTr > 3
                              ? undefined
                              : () => {
                                  treatmentsToAddOnChange(treatmentName)(['new']);
                                  treatmentAddEvents(treatmentName, true)()();
                                }
                          }
                        >
                          <AddButton
                            text={fm('doctorsOrders.rtms.backupSubjectOfTreatment.add')}
                            disabled={numberOfTr > 3}
                          />
                        </div>
                      </div>
                    )}
                    {showPreviousBackupSubjectsOfTreatment && (
                      <div>
                        <div style={{ padding: '0rem 1rem 0.5rem 1rem', color: colors.darkGray }}>
                          {fm('doctorsOrders.rtms.treatments.treatmentNameAndSpecifier')}
                        </div>
                        {previousBackupSubjectsOfTreatment.map((s, i) => (
                          <StyledListItem
                            key={i}
                            style={{
                              backgroundColor: i % 2 === 0 ? colors.lightestGray : undefined,
                              borderTop: i === 0 ? `1px solid ${colors.darkGray}` : undefined,
                            }}
                          >
                            <span style={{ fontWeight: 600 }}>{subjectOfTreatmentTitle(s, 'rtms', fm)}</span>
                            <ActionButtonRounded
                              text="doctorsOrders.rtms.backupSubjectOfTreatment.delete"
                              width={17}
                              height={3.5}
                              fontSize={16}
                              uppercase={false}
                              onClick={treatmentRemoveEvent(treatmentName)(i)}
                              disabled={treatmentsToAdd[treatmentName].includes('new')}
                            />
                          </StyledListItem>
                        ))}
                      </div>
                    )}
                  </React.Fragment>
                ),
              }[name]
            }
          </FormRow>
        );
      })}
    </React.Fragment>
  );
};

export default Treatments;
