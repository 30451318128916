import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import { styled } from '@mui/system';

import { Container, Item } from '../Grid';

import { historyValue } from '../../config/theme/componentTheme';
import CollapseElem from '../Collapse';
import colors from '../../config/theme/colors';
import { sortDocuments } from 'Utility/randomUtil';
import { isEmpty } from 'Utility/ramdaReplacement';

const HeaderRow = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'rightPadding',
})(({ rightPadding }: { rightPadding?: boolean }) => ({
  color: colors.tertiaryText,
  lineHeight: '2rem',
  '& > *': {
    ':not(:last-child)': {
      // Every child element except the last one
      wordBreak: 'break-word',
      paddingRight: rightPadding ? '1rem' : 0,
      boxSizing: 'border-box',
    },
  },
}));

const ContentsRow = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'rightPadding',
})(({ rightPadding }: { rightPadding?: boolean }) => ({
  '& > *': {
    ':not(:last-child)': {
      // Every child element except the last one
      wordBreak: 'break-word',
      paddingRight: rightPadding ? '1rem' : 0,
      boxSizing: 'border-box',
    },
  },
}));

const SingleHeader = styled(Item)({
  paddingRight: '2rem',
});

const Headers = ({ headers, makeDate, makeControls }: IOwnProps) => (
  <HeaderRow rightPadding={!(makeDate || makeControls)}>
    {makeDate && (
      <SingleHeader xs={2}>
        <FormattedMessage id={'general.date'} />
      </SingleHeader>
    )}
    {makeDate || makeControls ? (
      <Item xs={true}>
        <HeaderRow rightPadding>{headers}</HeaderRow>
      </Item>
    ) : (
      headers
    )}
    {makeControls && <Item xs={4} md={3} />}
  </HeaderRow>
);

const Contents = ({
  d,
  dateField,
  contentFormat,
  makeDate,
  makeControls,
  historyRowControls,
}: { d: document; dateField: string } & IOwnProps) => (
  <ContentsRow style={{ margin: '1rem 0 0 0', ...historyValue }} rightPadding={!(makeDate || makeControls)}>
    {makeDate && <Item xs={2}>{isPartialDate(d[dateField]) && formatPartialDate(d[dateField])}</Item>}
    {makeDate || makeControls ? (
      <Item xs={true}>
        <ContentsRow rightPadding>{contentFormat(d)}</ContentsRow>
      </Item>
    ) : (
      contentFormat(d)
    )}
    {makeControls && (
      <Item xs={4} md={3}>
        {historyRowControls?.(d)}
      </Item>
    )}
  </ContentsRow>
);

const HistoryListing = ({ documents, dateFieldName, timeFieldName, collapse, ...other }: IOwnProps): JSX.Element => {
  const dateField = dateFieldName || 'date';
  const timeField = timeFieldName || 'time';

  const sortedDocuments =
    documents &&
    documents.sort((n1, n2) =>
      sortDocuments([
        { type: 'date', sortField: dateField },
        { type: 'time', sortField: timeField },
      ])(n1, n2),
    );

  return (
    <React.Fragment>
      <Headers {...other} />
      {sortedDocuments && !isEmpty(sortedDocuments) && collapse ? (
        <React.Fragment>
          <Contents d={sortedDocuments[0]} dateField={dateField} {...other} />
          {sortedDocuments.length > 1 && (
            <CollapseElem
              localeIDs={{ showMessage: 'general.show', hideMessage: 'general.hide' }}
              amount={sortedDocuments.length - 1}
            >
              {sortedDocuments.slice(1).map((d: document) => (
                <Contents key={d._id} d={d} dateField={dateField} {...other} />
              ))}
            </CollapseElem>
          )}
        </React.Fragment>
      ) : (
        sortedDocuments && (
          <React.Fragment>
            {sortedDocuments.map((d: document) => (
              <Container alignItems="center" key={d._id} style={{ margin: '1rem 0 0 0', ...historyValue }}>
                <Contents key={d._id} d={d} dateField={dateField} {...other} />
              </Container>
            ))}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: document[];
  headers: JSX.Element;
  contentFormat: (d: document) => JSX.Element;
  dateFieldName?: string;
  timeFieldName?: string;
  collapse?: boolean;
  /** Automatically include date and historyrowcontrols. In this case contentFormat should only include the other fields */
  makeDate?: boolean;
  makeControls?: boolean;
  historyRowControls?: (d: document) => JSX.Element;
}

export default HistoryListing;
