import * as React from 'react';

import HistorySection from '../../../../../components/HistorySection';
import HistoryRowBasicItem from '../../../../../components/HistoryRowBasicItem';
import HistoryRowControls from '../../../../../components/HistoryRowControls';
import PlatformConditional from '../../../../../components/PlatformConditional';
import HistoryRowSubHeader from '../../../../../components/HistoryRowSubHeader';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import { msDiagnoses } from '../../../utils/definitions';
import { StyledHistory } from '../../../utils/styled';
import DMDhistory from '../DMDhistory';
import SMAhistory from '../SMAhistory';
import { Container, Item } from '../../../../../components/Grid';
import { IHistoryContext, withHistoryContext } from '../../../../../containers/FormContextHandler';
import { localeDefault, LocalizerCtx, TMessages } from '../../../../../containers/Localizer';
import browserLanguage from '../../../../../utility/browserLanguage';
import { sortDocuments } from '../../../../../utility/randomUtil';
import HistoryRowVerticalItem from '../../../../../components/HistoryRowVerticalItem';
import { assertCapabilities } from 'Store/index';
import PlatformCapabilities from '../../../../../config/capabilities';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import { isNil } from 'Utility/ramdaReplacement';

const MsType = (diagnosis: TDiagnosis, type?: Array<IMSType>): string => {
  switch (diagnosis) {
    case 'G35':
      return `g35.${
        Array.isArray(type)
          ? type.sort((t1, t2) => sortPartialDate(t1.date, t2.date)).reverse()?.[0]?.type ?? 'notDefined'
          : 'notDefined'
      }`;
    case 'G36.0':
      return 'g36';
    case 'G36.9':
      return 'g369';
    case 'G37.9':
      return 'g379';
    case 'radiologicallyIsolatedSyndrome':
      return 'radiologicallyIsolatedSyndrome';
    default:
      return '';
  }
};

const DiagnosisType = ({ document, fm }: { document: IDiagnosis; fm: (id: string) => string }): JSX.Element => {
  /**
   * Make a React element from a diagnosis' name. If localization is not defined, use the ICD-10 code by
   * itself (without any description).
   * @param value Messages provided to IntlProvider.
   * @param document The diagnosis document whose title to make.
   * @returns A string representing diagnosis title.
   */
  const makeTitle = (value: TMessages, document: IDiagnosis, locale?: Locale): React.ReactNode => {
    const localizations = locale ? value[locale] : value[browserLanguage] || value[localeDefault];
    // use either localized description or the ICD-10 code itself
    const dxTitle = localizations ? localizations[`diagnosis.opts.${document.diagnosis}`] : document.diagnosis;
    const type = document.typeOfDisease;
    const diagnosisType =
      document.diagnosis &&
      (msDiagnoses.includes(document.diagnosis)
        ? Array.isArray(type)
          ? fm('diagnosis.msTitle.' + MsType(document.diagnosis, type as Array<IMSType>))
          : ''
        : undefined);
    return `${dxTitle}${diagnosisType ? ` - ${diagnosisType}` : ''}`;
  };
  return (
    <LocalizerCtx.Consumer>
      {(value): React.ReactNode => document && makeTitle(value.messages, document, value.locale)}
    </LocalizerCtx.Consumer>
  );
};

const DiagnosisItemComponent = ({
  diagnosis,
  startEdit,
  fm,
  capabilityGroups,
}: IDiagnosisItemProps & ICapabilityContextProps): JSX.Element => {
  const isLicensedNMOSD = assertCapabilities([PlatformCapabilities.NMOSD_CAPABILITES], capabilityGroups);
  return (
    <HistorySection headerText={<DiagnosisType document={diagnosis} fm={fm} />}>
      <Container alignItems="center">
        <Item xs={2}>
          <HistoryRowBasicItem
            header={fm('general.date')}
            value={diagnosis.date && formatPartialDate(diagnosis.date)}
          />
        </Item>
        <Item xs={6}>
          {diagnosis.diagnosis === 'unspecifiedDiagnosis' && (
            <HistoryRowBasicItem header={fm('diagnosis.unspecified.details')} value={diagnosis.unspecifiedDetails} />
          )}
        </Item>
        <Item xs={4}>{startEdit && <HistoryRowControls document={diagnosis} startEdit={startEdit} />}</Item>
      </Container>
      <Container>
        <Item xs={12}>
          {diagnosis.diagnosis === 'G35' &&
            Array.isArray(diagnosis.typeOfDisease) &&
            diagnosis.typeOfDisease.length > 0 && (
              <React.Fragment>
                <div style={{ marginTop: '3rem' }}>
                  <HistoryRowSubHeader header={fm(`diagnosis.msType.title`)} />
                </div>
                {diagnosis.typeOfDisease.map((t: IMSType, i): JSX.Element => {
                  const hasDitOrDis = !isNil(t.dit) || !isNil(t.dis);
                  return (
                    <div
                      key={t.id}
                      style={{ marginBottom: i < (diagnosis.typeOfDisease?.length || 0) - 1 ? '1rem' : undefined }}
                    >
                      <Container style={{ marginBottom: '2rem' }}>
                        <Item xs={2} style={{ fontWeight: hasDitOrDis ? 600 : undefined }}>
                          {formatPartialDate(t.date)}
                        </Item>
                        <Item xs={3}>{hasDitOrDis && fm('diagnosis.msType.title')}</Item>
                        <Item xs={7} style={{ fontWeight: 600 }}>
                          {t.type && fm(`diagnosis.msType.name.${t.type}`)}
                        </Item>
                      </Container>
                      {!isNil(t.dit) && (
                        <Container style={{ marginBottom: '2rem' }}>
                          <Item xs={2} />
                          <Item xs={3}>{fm('diagnosis.msType.dit')}</Item>
                          <Item xs={7} style={{ fontWeight: 600 }}>
                            {t.type && fm(`diagnosis.opts.yesNoUnknown.${t.dit}`)}
                          </Item>
                        </Container>
                      )}
                      {!isNil(t.dis) && (
                        <Container style={{ marginBottom: '2rem' }}>
                          <Item xs={2} />
                          <Item xs={3}>{fm('diagnosis.msType.dis')}</Item>
                          <Item xs={7} style={{ fontWeight: 600 }}>
                            {t.type && fm(`diagnosis.opts.yesNoUnknown.${t.dis}`)}
                          </Item>
                        </Container>
                      )}
                    </div>
                  );
                })}
              </React.Fragment>
            )}
          {['G36.9', 'G37.9', 'G36.0'].includes(diagnosis.diagnosis ?? '') && !isLicensedNMOSD && (
            <React.Fragment>
              <div style={{ marginTop: '3rem' }}>
                <HistoryRowSubHeader header={fm('diagnosis.keoType.title')} />
              </div>
              <HistoryRowVerticalItem
                header={fm('diagnosis.keoType.title')}
                value={diagnosis.keoType ? fm(`diagnosis.keoType.opts.${diagnosis.keoType}`) : '-'}
              />
            </React.Fragment>
          )}
          {['G70.0'].includes(diagnosis.diagnosis ?? '') &&
            Array.isArray(diagnosis.formOfDisease) &&
            diagnosis.formOfDisease.length > 0 && (
              <React.Fragment>
                <div style={{ marginTop: '3rem' }}>
                  <HistoryRowSubHeader header={fm('diagnosis.mgravis.formOfDisease.formOfDisease')} />
                </div>
                {diagnosis.formOfDisease.map((t: IMGravisFormOfDisease, i): JSX.Element => {
                  return (
                    <div
                      key={t.id}
                      style={{ marginBottom: i < (diagnosis.typeOfDisease?.length || 0) - 1 ? '1rem' : undefined }}
                    >
                      <Container style={{ marginBottom: '2rem' }}>
                        <Item xs={2}>{formatPartialDate(t.date)}</Item>
                        <Item xs={7} style={{ fontWeight: 600 }}>
                          {t.formOfDisease && fm(`diagnosis.mgravis.formOfDisease.${t.formOfDisease}`)}
                        </Item>
                      </Container>
                    </div>
                  );
                })}
              </React.Fragment>
            )}
          <PlatformConditional platform="sma">
            <SMAhistory document={diagnosis} />
          </PlatformConditional>
          <PlatformConditional platform="dmd">
            <DMDhistory document={diagnosis} />
          </PlatformConditional>
        </Item>
      </Container>
    </HistorySection>
  );
};

const DiagnosisItem = withHistoryContext(withCapabilities(DiagnosisItemComponent));

interface IDiagnosisItemProps extends IHistoryContext {
  diagnosis: IDiagnosis;
}

const DiagnosisHistory = ({ documents }: IDiagnosisHistory): JSX.Element => (
  <StyledHistory>
    {documents &&
      documents
        .sort((n1, n2) => sortDocuments([{ type: 'date', sortField: 'date' }])(n1, n2))
        .map((diagnosis: IDiagnosis) => (
          <React.Fragment key={diagnosis._id}>
            <DiagnosisItem diagnosis={diagnosis} />
          </React.Fragment>
        ))}
  </StyledHistory>
);

interface IDiagnosisHistory {
  documents: IDiagnosis[];
}

export default DiagnosisHistory;
