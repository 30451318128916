import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';

import { formatPartialDate, formatTime, partialDateToValue, sortPartialDate, nowPartialDate } from 'neuro-utils';
import colors from '../../../../../config/theme/colors';

import MedicationSearchDialog from './MedicationSearchDialog';
import { pausationReasons } from '../../config';
import { pauseIsActive } from '../../../utils';

import { actions } from '../../../../../store/form';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import Administration from './Administration';
import RegimenStepper from './RegimenDialog';
import { path, uniq } from 'Utility/ramdaReplacement';

const StyledValueArea = styled('div')({
  marginBottom: '3rem',
});

const optionFormatter = (n: string | number): JSX.Element => <FormattedMessage id={`medication.opts.${n}`} />;

interface DateLimits {
  dateHookFloor?: PartialDate | undefined;
  dateHookCeiling?: PartialDate | undefined;
}
/**
 * Get possible lower and upper bounds for pause start date.
 * @param {IMedication} medicDoc medication document in formData
 * @returns possible lower and upper bounds for pause start date
 */
const getPauseStartDateLimits = (medicDoc: IMedication): DateLimits => {
  return {
    dateHookFloor: path(['pauses', 1, 'endDate'], medicDoc) || medicDoc.startDate,
    dateHookCeiling: path(['hasEnded', 0], medicDoc) ? medicDoc.endDate : undefined,
  };
};

/**
 * Get default pause date within the provided limits.
 * @param {DateLimits} limits date limits for pause
 * @returns default pause date
 */
const getPauseDefaultDate = (limits: DateLimits): PartialDate => {
  const floorValue = limits.dateHookFloor && partialDateToValue(limits.dateHookFloor);
  const ceilingValue = limits.dateHookCeiling && partialDateToValue(limits.dateHookCeiling);
  const nowValue = partialDateToValue(nowPartialDate());

  if (floorValue && floorValue > nowValue) return limits.dateHookFloor!;
  if (ceilingValue && ceilingValue < nowValue) return limits.dateHookCeiling!;

  return nowPartialDate();
};

/**
 * Get possible lower and upper bounds for pause end date.
 * @param {IMedication} medicDoc medication document in formData
 * @returns possible lower and upper bounds for pause end date
 */
const getPauseEndDateLimits = (medicDoc: IMedication): DateLimits => {
  return {
    dateHookFloor: path(['pauses', 0, 'startDate'], medicDoc) || medicDoc.startDate,
    dateHookCeiling: path(['hasEnded', 0], medicDoc) ? medicDoc.endDate : undefined,
  };
};

const platformEndedReasons = (platform?: string): string[] => {
  if (platform === 'ms') return ['diseaseNatureChange', 'antibodies', 'pregnancy', 'jcvirus'];
  if (platform === 'sma' || platform === 'dmd') return ['diseaseNatureChange'];
  if (platform === 'epilepsy') return ['pregnancyOrPlannedPregnancy'];
  if (platform === 'sleepApnea') return [];
  return [];
};

const RegimenSection = withFormContext(
  ({
    formData = {
      document: {},
      onChange: (): void => {
        return;
      },
    },
  }: IFormContext): JSX.Element => {
    return (
      <React.Fragment>
        <FormSection header="medication.regimen">
          <RegimenStepper formData={formData} />
        </FormSection>
      </React.Fragment>
    );
  },
);

const emptyPartialDate = (d: PartialDate): boolean => d.every((n) => !n);

const pauseOnChangeMutator = (event: IMedicationPause, name?: string): IMedicationPause => {
  let changedEvent = event;
  if (name === 'endDate' && (!event.endDate || emptyPartialDate(event.endDate))) {
    changedEvent = { ...changedEvent, endTime: undefined };
  }
  return changedEvent;
};

const PausationSection = withFormContext(({ formData }: IFormContext<IMedication>): JSX.Element => {
  const { document } = formData;

  const pauses = document.pauses as IMedicationPause[] | undefined;

  return (
    <FormSection header="medication.pausations">
      <EventStepper
        name="pauses"
        formData={formData}
        stepLabelText={(p: IMedicationPause): JSX.Element => {
          return (
            <>
              <span>{formatPartialDate(p.startDate)} </span>
              {(!p.endDate || p.endDate.every((n) => !n)) && (
                <>
                  <span>(</span>
                  <FormattedMessage id="medication.pauseActive" />
                  <span>)</span>
                </>
              )}
            </>
          );
        }}
        stepContent={(p: IMedicationPause): JSX.Element => {
          return (
            <div style={{ marginBottom: '1rem' }}>
              <StepperHeaderValuePair
                header={<FormattedMessage id="medication.pausationDate" />}
                value={`${formatPartialDate(p.startDate)}` + (p.startTime ? `, ${formatTime(p.startTime)}` : '')}
              />
              {p.endDate && !p.endDate.every((n) => !n) && (
                <StepperHeaderValuePair
                  header={<FormattedMessage id="medication.continuationDate" />}
                  value={
                    formatPartialDate(p.endDate) +
                    (p.endDate && p.endTime && !p.endDate.every((n) => !n) ? `, ${formatTime(p.endTime)}` : '')
                  }
                />
              )}
              <StepperHeaderValuePair
                header={<FormattedMessage id="medication.pausationReason" />}
                value={
                  p.pausationReason && <FormattedMessage id={`medication.pausationReasons.${p.pausationReason}`} />
                }
              />
              <StepperHeaderValuePair
                header={<FormattedMessage id="medication.pausationAdditionalInfoPlaceholder" />}
                value={p.additionalInfo}
              />
            </div>
          );
        }}
        addNewTextHeader="medication.newPause"
        addNewTextButton="medication.newPause"
        buttonDisabled={pauseIsActive(document)}
        buttonDisabledMessage={
          pauseIsActive(document) ? <FormattedMessage id="medication.endPreviousMessage" /> : undefined
        }
        previousEventsTextHeader="medication.pausations"
        mutators={{ onChangeMutator: pauseOnChangeMutator }}
        editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
          <React.Fragment>
            <div style={{ marginBottom: '2rem' }}>
              <div style={{ color: colors.darkGray }}>
                <FormattedMessage id="medication.pausationDate" />
              </div>
              <InputHandler
                type="PartialDate"
                name="startDate"
                editing={true}
                isNotCancellable={true}
                dateDefault={getPauseDefaultDate(getPauseStartDateLimits(document))}
                formData={{ onChange, document: { startDate: pauses?.[index]?.startDate || '' } }}
                dateHook={getPauseStartDateLimits(document)}
              />
            </div>
            {pauses && (
              <div style={{ marginBottom: '2rem' }}>
                <div style={{ color: colors.darkGray }}>
                  <FormattedMessage id="medication.pausationTime" />
                </div>
                <InputHandler
                  type="TimePicker"
                  editing={true}
                  name="startTime"
                  formData={{
                    onChange,
                    document: { startTime: pauses?.[index]?.startTime || '' },
                  }}
                  width={11}
                />
              </div>
            )}
            <div style={{ marginBottom: '2rem' }}>
              <div style={{ color: colors.darkGray }}>
                <FormattedMessage id="medication.pausationReason" />
              </div>
              <InputHandler
                type="Select"
                name="pausationReason"
                editing={true}
                formData={{ onChange, document: { pausationReason: pauses?.[index]?.pausationReason || undefined } }}
                options={pausationReasons}
                placeholder="medication.pausationReasonPlaceholder"
                optionFormatter={(name: string | number): string | JSX.Element => (
                  <FormattedMessage id={`medication.pausationReasons.${name}`} />
                )}
              />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <div style={{ color: colors.darkGray }}></div>
              <InputHandler
                type="TextArea"
                name="additionalInfo"
                editing={true}
                formData={{ onChange, document: { additionalInfo: pauses?.[index]?.additionalInfo || '' } }}
                placeholder={'medication.pausationAdditionalInfoPlaceholder'}
              />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <div style={{ color: colors.darkGray }}>
                <FormattedMessage id="medication.continuationDate" />
              </div>
              <InputHandler
                type="PartialDate"
                name="endDate"
                editing={true}
                dateDefault={undefined}
                formData={{
                  onChange,
                  document: { endDate: pauses?.[index]?.endDate || '' },
                }}
                dateHook={getPauseEndDateLimits(document)}
              />
            </div>
            {pauses && (
              <div style={{ marginBottom: '2rem' }}>
                <div style={{ color: colors.darkGray }}>
                  <FormattedMessage id="medication.continuationTime" />
                </div>
                <InputHandler
                  type="TimePicker"
                  name="endTime"
                  editing={true}
                  formData={{ onChange, document: { endTime: pauses?.[index]?.endTime || '' } }}
                  width={11}
                />
              </div>
            )}
          </React.Fragment>
        )}
      />
    </FormSection>
  );
});

export const endingReasons = (thisEndReason?: string[], platform?: string): string[] => {
  switch (platform) {
    case 'ms': {
      return uniq([
        'inefficient',
        'radiologicallyInefficient',
        'adverseEffects',
        ...platformEndedReasons(platform),
        'compliance',
        'other',
        'unknown',
        ...(thisEndReason || []), // Currently selected reasons added as options
      ]);
    }
    default: {
      return uniq([
        'inefficient',
        'adverseEffects',
        ...platformEndedReasons(platform),
        'compliance',
        'other',
        'unknown',
        ...(thisEndReason || []), // Currently selected reasons added as options
      ]);
    }
  }
};

const EndSection = withFormContext(({ formData, editing }: IFormContext): JSX.Element => {
  const platform = useSelector((s: { session?: ISessionStore }) => s.session?.platforms?.selected);

  return (
    <React.Fragment>
      {formData && (
        <FormSection header="medication.ended">
          <FormRow title="medication.stopMedication" id={'endMedication'}>
            <InputHandler
              type="Checkbox"
              editing={!!editing}
              name="hasEnded"
              formData={formData}
              options={['true']}
              optionFormatter={(): JSX.Element => <FormattedMessage id="medication.stopMedication" />}
              dependentFieldsList={(value: TFieldValue, prevValue: TFieldValue) => {
                /** Since depended fields list stops date default working on conditional FormRows, disable this prop when first clicking on ending checkbox */
                if (
                  Array.isArray(value) &&
                  value.length === 1 &&
                  ((Array.isArray(prevValue) && prevValue.length === 0) || !prevValue)
                ) {
                  return [];
                }

                const dependentFields = ['endDate', 'endTime', 'endReason', 'endReasonPrimary'];
                if (formData.document.endReasonOther?.length > 0) dependentFields.push('endReasonOther');
                return dependentFields;
              }}
              dependentFieldsRemovalWarning
            />
          </FormRow>
          <FormRow title="medication.endedDate" condition={formData.document.hasEnded?.[0] === true}>
            <InputHandler
              type="PartialDate"
              editing={!!editing}
              name="endDate"
              formData={formData}
              dateDefault="now"
              dateHook={{ dateHookFloor: formData.document.startDate }}
              isNotCancellable
            />
          </FormRow>
          <FormRow title="medication.endedTime" condition={formData.document.hasEnded?.[0] === true}>
            <InputHandler type="TimePicker" editing={!!editing} name="endTime" formData={formData} />
          </FormRow>
          <FormRow title="medication.endedReason" condition={formData.document.hasEnded?.[0] === true}>
            <InputHandler
              type="Checkbox"
              editing={!!editing}
              name="endReason"
              formData={formData}
              options={endingReasons(formData.document.endReason, platform)}
              optionFormatter={optionFormatter}
            />
          </FormRow>

          <FormRow
            title="medication.endedReasonPrimary"
            condition={formData.document.hasEnded?.[0] === true && formData.document.endReason?.length > 1}
          >
            <InputHandler
              type="Select"
              editing={!!editing}
              name="endReasonPrimary"
              formData={formData}
              options={formData.document.endReason || []}
              optionFormatter={optionFormatter}
              placeholder="medication.endedReasonPrimaryPlaceholder"
            />
          </FormRow>
          <FormRow title="medication.endedReasonOther" condition={formData.document.hasEnded?.[0] === true}>
            <InputHandler
              type="TextArea"
              editing={!!editing}
              name="endReasonOther"
              formData={formData}
              placeholder={'medication.pausationAdditionalInfoPlaceholder'}
            />
          </FormRow>
        </FormSection>
      )}
    </React.Fragment>
  );
});

const MedicationForm = ({
  medication,
  editing,
  formData,
}: IMedicationFormProps & IFormContext<IMedication>): JSX.Element => {
  const { document, onChange } = formData;

  const [firstRegimenDate, setFirstRegimenDate] = React.useState<PartialDate>(nowPartialDate());

  // Function to decide if warning message is shown
  const warningMessageBoolean = (comparedDate: PartialDate): boolean => {
    if (!document.regimen || document.regimen.length === 0) return false;
    const sortedByDates = document.regimen.sort((r1: any, r2: any) => sortPartialDate(r1.date, r2.date));
    sortedByDates[0].date && setFirstRegimenDate(sortedByDates[0].date);
    return partialDateToValue(firstRegimenDate) < partialDateToValue(comparedDate);
  };

  // Previous end reason count
  const [prevEndReasonsCount, setprevEndReasonsCount] = React.useState<undefined | number>(document.endReason?.length);

  React.useEffect(() => {
    const endReasons = (document.endReason || []) as string[];
    if (
      // Set primary reason automatically if there is only one ending reason
      endReasons.length === 1
    ) {
      onChange?.({
        endReasonPrimary: endReasons[0],
      });
    } else if (
      endReasons.length === 0 ||
      prevEndReasonsCount === 1 ||
      (document.endReasonPrimary && !endReasons.includes(document.endReasonPrimary))
    ) {
      // Set primary reason to null if 0/undefined reasons, or previously only one reason
      onChange?.({
        endReasonPrimary: null,
      });
    }

    // Set this for next reasons change
    setprevEndReasonsCount(endReasons?.length);
  }, [document.endReason, document.endReasonPrimary, onChange, prevEndReasonsCount]);

  const dispatch = useDispatch();
  // Previous isClinicalStudy
  const [prevCS, setPrevCS] = React.useState<boolean | undefined>(formData.document.isClinicalStudy?.[0]);

  React.useEffect(() => {
    if (prevCS === document.isClinicalStudy?.[0]) return;
    if ((document.isClinicalStudy?.[0] === true && !prevCS) || (!document.isClinicalStudy?.[0] && prevCS)) {
      editing &&
        dispatch(
          actions.updateFormValuesBatch({ medicationName: null, medicationSubstances: null, regimen: null }, editing),
        );
      setPrevCS(document.isClinicalStudy?.[0]);
    }
  }, [document.isClinicalStudy, dispatch, editing, prevCS]);

  return (
    <React.Fragment>
      <FormSection header="medication.startDate">
        <FormRow title="medication.startDate">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="startDate"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
            dateHook={{ dateHookCeiling: document.endDate }}
            warningMessage={'medication.checkTheRegimenDates'}
            showMessage={warningMessageBoolean}
          />
        </FormRow>
        <FormRow title="medication.startTime">
          <InputHandler type="TimePicker" editing={!!editing} name="startTime" formData={formData} />
        </FormRow>
        <FormRow title="medication.usageStartDate">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="usageStartDate"
            formData={formData}
            dateHook={{ dateHookCeiling: document.endDate }}
          />
        </FormRow>
      </FormSection>
      <FormSection header="medication.medication">
        <FormRow title="medication.chooseMedication" condition={document.isClinicalStudy?.[0] !== true}>
          {document.medicationName ? (
            <StyledValueArea>
              {document.medicationName + (document.medicationSubstances ? ` (${document.medicationSubstances})` : '')}
            </StyledValueArea>
          ) : undefined}
          <MedicationSearchDialog
            formData={formData as { document: IMedication; onChange: IFormData['onChange'] }}
            disabled={
              medication?._commitsLength && medication?._commitsLength > 1
                ? !document.medicationName
                  ? false
                  : true
                : false
            }
            disabledTooltip={<FormattedMessage id="medication.chooseMedicationDisabledMessage" />}
          />
        </FormRow>

        <FormRow title="medication.clinicalStudyMedication">
          <InputHandler
            type="Checkbox"
            editing={true}
            name="isClinicalStudy"
            formData={formData}
            options={['true']}
            optionFormatter={(): JSX.Element => <FormattedMessage id="general.yes" />}
            disabled={
              medication?._commitsLength && medication?._commitsLength > 1
                ? !formData.document.medicationName
                  ? false
                  : true
                : false
            }
          />
        </FormRow>

        <FormRow title="medication.medication" condition={document.isClinicalStudy?.[0] === true}>
          <InputHandler
            type="TextField"
            editing={true}
            name="medicationName"
            formData={formData}
            placeholder={'medication.medication'}
          />
        </FormRow>

        <FormRow title="medication.substances" condition={document.isClinicalStudy?.[0] === true}>
          <InputHandler
            type="TextArea"
            editing={true}
            name="medicationSubstances"
            formData={formData}
            placeholder={'medication.substances'}
          />
        </FormRow>
      </FormSection>
      <React.Fragment>
        {document.medicationName && <RegimenSection />}
        <Administration />
        <PausationSection />
        <EndSection />
      </React.Fragment>
    </React.Fragment>
  );
};

interface IMedicationFormProps {
  editing?: string;
  formData: IFormData<IMedication>;
  medication?: IMedication;
}

export default withFormContext(MedicationForm);
