import { path } from 'Utility/ramdaReplacement';
import { basicDefaultValuesRTMS, subjectOfTreatmentNames } from './config';

const getBasicDefaultValueRTMS = (
  stimulationType: IRTMSTreatmentEvent['stimulationType'],
  subjectOfTreatmentName: string,
  field: keyof Pick<
    IRTMSTreatmentEvent,
    | 'pulseFrequency'
    | 'pulseFrequency3PulseBurst'
    | 'frequencyBetweenBursts'
    | 'amountOfBurstsInSet'
    | 'amountOfSetsInSequence'
    | 'timeBetweenBurstsets'
    | 'pulseIntensity'
    | 'numberOfPulses'
    | 'pauseLength'
  >,
): undefined | number | Array<number> | null => {
  if (!stimulationType) return undefined;
  if (!subjectOfTreatmentNames.includes(subjectOfTreatmentName)) return undefined;
  if (stimulationType === 'cTBS' || stimulationType === 'iTBS') {
    return basicDefaultValuesRTMS[field][stimulationType];
  }
  const pathArr = subjectOfTreatmentName.split('.');
  return path(pathArr, basicDefaultValuesRTMS[field][stimulationType]) ?? null;
};

const getOrgDefaultValueRTMS = (
  stimulationType: IRTMSTreatmentEvent['stimulationType'],
  subjectOfTreatmentName: string,
  field: keyof Pick<
    IRTMSTreatmentEvent,
    | 'pulseFrequency'
    | 'pulseFrequency3PulseBurst'
    | 'frequencyBetweenBursts'
    | 'amountOfBurstsInSet'
    | 'amountOfSetsInSequence'
    | 'timeBetweenBurstsets'
    | 'pulseIntensity'
    | 'numberOfPulses'
    | 'pauseLength'
  >,
  orgSettings: IOrgSettings,
): undefined | number | Array<number> | null => {
  if (!stimulationType) return undefined;
  if (!subjectOfTreatmentNames.includes(subjectOfTreatmentName)) return undefined;
  const keyString =
    stimulationType === 'cTBS' || stimulationType === 'iTBS'
      ? `${field}.${stimulationType}`
      : `${field}.${stimulationType}.${subjectOfTreatmentName}`;
  const value = orgSettings[keyString];
  if (
    typeof value === 'number' ||
    value === undefined ||
    value === null ||
    (Array.isArray(value) && value.every((v) => typeof v === 'number'))
  ) {
    return value;
  }
  return undefined;
};

/**
 * Whether to show the other fields concerning doctors orders. (Show if the order was not only used to end rtms and/or tdcs)
 * @param {IDoctorsOrder & IDoctorsOrderOther} doc - Doctors order document
 * @returns {boolean} To show or not to show
 */
const showOtherFields = (doc: IDoctorsOrder): boolean => {
  const rtms = doc.rtms;
  const tdcs = doc.tdcs;
  const otherFields = ['nextRMT', 'nextInterview', 'ninmtMeeting', 'addedToAgenda', 'requestFollowUp'];
  if (rtms && Object.keys(rtms).length > 0) {
    if (rtms.endRTMS) return false;
  }
  if (tdcs && Object.keys(tdcs).length > 0) {
    if (tdcs.endTDCS) return false;
  }
  if (Object.keys(doc).some((key) => otherFields.includes(key))) {
    return true;
  }
  return false;
};

export { getBasicDefaultValueRTMS, getOrgDefaultValueRTMS, showOtherFields };
