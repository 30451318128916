import * as React from 'react';
import { styled } from '@mui/system';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import FormSection from '../../../../../components/FormSection';
import { maxNpiScores, npiFormInformation, npiFormStructure } from './definitions';
import Unit from '../../../../../components/Unit';
import { ReferenceText } from '../../../../Diagnosis/utils/styled';
import { InformationButton, NumberedFormRow } from '../../../components';
import {
  calculateParkinsonDiseaseDuration,
  calculateNpiFrequencyAndSeverityScore,
  calculateNpiStressScore,
} from '../../../utils';
import {
  exists,
  formatPartialDate,
  isPartialDate,
  nowPartialDate,
  partialDateToValue,
  sortPartialDate,
} from 'neuro-utils';
import FormSectionBar from 'Components/FormSectionBar';
import { omit, path } from 'Utility/ramdaReplacement';
import { mmseTotalScore } from 'Routes/Cognition/utils';

const SubHeader = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  fontWeight: 600,
  margin: '0 0 1rem 0',
});

const NPIForm = ({ formData, view, fm, documents }: IFormContext<INPI>): JSX.Element => {
  const viewing = !!view?.viewing;
  const diagnosisDocuments: IDiagnosis[] = (documents ?? [])?.filter((d) => d._type === 'diagnosis') as IDiagnosis[];

  const diseaseDuration = calculateParkinsonDiseaseDuration(diagnosisDocuments);

  React.useEffect(() => {
    // Save latest MMSE date and score to document on mount
    // latestMMSE = latest MMSE when the document was created / edited if no MMSE was previously saved

    if (formData.document.latestMMSE) return;

    const npiDate = partialDateToValue(formData.document.date || nowPartialDate());
    const latestMMSE: IMMSE | undefined = documents
      ?.filter((d) => d._type === 'mmse' && (d.unableToPerform || exists(d.totalScore) || exists(mmseTotalScore(d))))
      .sort((a, b) => sortPartialDate(b.date, a.date))
      .find((d) => partialDateToValue(d.date) <= npiDate);

    if (latestMMSE) {
      formData.onChange?.({
        latestMMSE: {
          date: latestMMSE.date,
          unableToPerform: latestMMSE.unableToPerform,
          totalScore: latestMMSE.totalScore || mmseTotalScore(latestMMSE),
        },
      });
    }
  }, []);

  // If NPI date is changed, check if the MMSE doc shown on form should be changed also
  // This is also triggered on first mount
  const onDateChange = (onChangeValues: TOnChangeValues): void => {
    const name = Object.keys(onChangeValues)[0];
    const value = onChangeValues[name];
    const dateValue = isPartialDate(value) ? partialDateToValue(value) : 0;
    // Find previous mmse doc
    const previousMMSE: IMMSE | undefined = documents
      ?.filter((d) => d._type === 'mmse' && (d.unableToPerform || exists(d.totalScore) || exists(mmseTotalScore(d))))
      .sort((a, b) => sortPartialDate(b.date, a.date))
      .find((d) => partialDateToValue(d.date) <= dateValue);

    if (previousMMSE) {
      formData.onChange?.({
        latestMMSE: {
          date: previousMMSE?.date,
          unableToPerform: previousMMSE?.unableToPerform,
          totalScore: previousMMSE?.totalScore || mmseTotalScore(previousMMSE),
        },
      });
    }
    // Change MMSE back to undefined if the date was changed and theres no previous MMSE
    if (formData.document.latestMMSE && name === 'date' && !previousMMSE) {
      formData.onChange?.({
        latestMMSE: undefined,
      });
    }

    formData.onChange &&
      formData.onChange({
        [name]: value,
      });
  };

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={!viewing}
            name="date"
            formData={{ onChange: onDateChange, document: formData.document }}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title={'diagnosticSurvey.npi.latestMMSEResultFromSystem'}>
          {formData.document.latestMMSE?.unableToPerform || exists(formData.document.latestMMSE?.totalScore) ? (
            <React.Fragment>
              <div>
                {formData.document.latestMMSE?.unableToPerform
                  ? fm('cognition.unableToPerform')
                  : formData.document.latestMMSE?.totalScore}
              </div>
              {formData.document?.latestMMSE?.date ? (
                <div>({formatPartialDate(formData.document?.latestMMSE.date)})</div>
              ) : null}
            </React.Fragment>
          ) : (
            fm('diagnosticSurvey.npi.noMMSEResults')
          )}
        </FormRow>
        <FormRow title={'diagnosticSurvey.npi.diseaseDuration'}>
          <React.Fragment>
            {fm('general.years', { N: diseaseDuration.years })} {fm('general.months', { N: diseaseDuration.months })}{' '}
            {fm('general.days', { N: diseaseDuration.days })}
          </React.Fragment>
        </FormRow>
        <FormRow title={'diagnosticSurvey.npi.interviewee'}>
          <InputHandler
            type="Radio"
            editing={!viewing}
            name="interviewee"
            formData={formData}
            options={['spouse', 'family', 'patient', 'other']}
            optionFormatter={(id: string | number): string => fm(`diagnosticSurvey.npi.opts.interviewee.${id}`)}
          />
        </FormRow>
      </FormSection>
      {Object.keys(npiFormStructure).map((key: string, i: number) => {
        if (key !== 'summary') {
          return (
            <React.Fragment key={key}>
              {(key === 'delusions' || key === 'sleepDisorders') && (
                <FormSectionBar
                  header={`diagnosticSurvey.npi.${
                    { delusions: 'neuropsychiatricSymptoms', sleepDisorders: 'neurovegetativeChanges' }[key]
                  }`}
                />
              )}
              <FormSection header={`diagnosticSurvey.npi.${key}.title`}>
                <SubHeader>
                  {fm('diagnosticSurvey.npi.screeningQuestion')}:{' '}
                  <InformationButton
                    info={npiFormInformation.screeningQuestions.concat(
                      i > 9 ? npiFormInformation.neurovegetativeChanges : [],
                    )}
                  />
                </SubHeader>
                <FormRow title={npiFormStructure[key].screeningQuestion.title}>
                  <InputHandler
                    type="Radio"
                    editing={!viewing}
                    name={`${key}.${npiFormStructure[key].screeningQuestion.title.split('.').pop()}`}
                    formData={formData}
                    options={npiFormStructure[key].screeningQuestion.options}
                    optionFormatter={(id: string | number): string =>
                      fm(`${npiFormStructure[key].screeningQuestion.optionFormat}.${id}`)
                    }
                  />
                </FormRow>
                {path([key, npiFormStructure[key].screeningQuestion.title.split('.').pop()], formData.document) ===
                  'yes' && (
                  <React.Fragment>
                    <SubHeader>
                      {fm('diagnosticSurvey.npi.additionalQuestions')}:{' '}
                      <InformationButton info={npiFormInformation.additionalQuestions} />
                    </SubHeader>
                    {npiFormStructure[key].additionalQuestions.map(
                      (question: { [key: string]: any }, index: number) => {
                        return (
                          <NumberedFormRow key={question.title} number={index + 1} title={fm(question.title)}>
                            <InputHandler
                              type="Radio"
                              editing={!viewing}
                              name={`${key}.${question.title.split('.').pop()}`}
                              formData={formData}
                              options={question.options}
                              optionFormatter={(id: string | number): string => fm(`${question.optionFormat}.${id}`)}
                            />
                          </NumberedFormRow>
                        );
                      },
                    )}
                    <SubHeader>{fm('diagnosticSurvey.npi.frequencySeverityAndStress')}:</SubHeader>
                    {npiFormStructure[key].frequencySeverityAndStress.map(
                      (question: { [key: string]: any }, index: number) => {
                        return (
                          <NumberedFormRow
                            key={question.title}
                            title={fm(`${question.title}.title`)}
                            info={
                              index < 2 ? (
                                <InformationButton info={npiFormInformation[question.title.split('.').pop()]} />
                              ) : undefined
                            }
                          >
                            <InputHandler
                              type="Radio"
                              editing={!viewing}
                              name={`${key}.${question.title.split('.').pop()}`}
                              formData={{
                                onChange: (values: TOnChangeValues): void => {
                                  const value = { [Object.keys(values)[0]]: Number(values[Object.keys(values)[0]]) };
                                  formData.onChange?.(value);
                                },
                                document: formData.document,
                              }}
                              options={question.options}
                              optionFormatter={(id: string | number): string => fm(`${question.optionFormat}.${id}`)}
                              // Spacing gets too tight with longer options so apply this styling on those fields
                              style={i > 1 && index === 1 ? { marginBottom: '2rem' } : undefined}
                            />
                          </NumberedFormRow>
                        );
                      },
                    )}
                  </React.Fragment>
                )}
              </FormSection>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={key}>
              <FormSectionBar header="diagnosticSurvey.npi.summary" />
              <FormSection key={key}>
                {npiFormStructure[key].map((field: string, index: number) => {
                  const score = {
                    frequencyAndSeverityTotalScoreFromDelusionsToAbnormalMotoricBehavior:
                      calculateNpiFrequencyAndSeverityScore(
                        omit(['sleepDisorders', 'appetiteAndEatingDisorders'], formData.document),
                      ),
                    stressTotalScoreFromDelusionsToAbnormalMotoricBehavior: calculateNpiStressScore(
                      omit(['sleepDisorders', 'appetiteAndEatingDisorders'], formData.document),
                    ),
                    frequencyAndSeverityTotalScoreFromSleepDisorders: calculateNpiFrequencyAndSeverityScore({
                      sleepDisorders: formData.document.sleepDisorders,
                    } as INPI),
                    stressTotalScoreFromSleepDisorders: path(['sleepDisorders', 'stress'], formData.document) as number,
                    frequencyAndSeverityTotalScoreFromEatingAndAppetiteDisorders: calculateNpiFrequencyAndSeverityScore(
                      {
                        appetiteAndEatingDisorders: formData.document.appetiteAndEatingDisorders,
                      } as INPI,
                    ),
                    stressTotalScoreFromEatingAndAppetiteDisorders: path(
                      ['appetiteAndEatingDisorders', 'stress'],
                      formData.document,
                    ) as number,
                  }[field];
                  return (
                    <React.Fragment key={field}>
                      {[0, 2].includes(index) && (
                        <SubHeader>
                          {fm(
                            `diagnosticSurvey.npi.${
                              index === 0 ? 'neuropsychiatricSymptoms' : 'neurovegetativeChanges'
                            }`,
                          )}
                          :{' '}
                        </SubHeader>
                      )}
                      <FormRow title={`diagnosticSurvey.npi.${field}`}>
                        <Unit unit={`/ ${maxNpiScores[field]}`} fontWeight={viewing ? 'bold' : 'normal'}>
                          <React.Fragment>{exists(score) ? score : '-'}</React.Fragment>
                        </Unit>
                      </FormRow>
                    </React.Fragment>
                  );
                })}
                <ReferenceText>
                  Cummings JL et al. The Neuropsychiatric Inventory: Comprehensive assessment of psychopathology in
                  dementia. Neurology 1994;44:2308-2314.
                </ReferenceText>
              </FormSection>
            </React.Fragment>
          );
        }
      })}
    </React.Fragment>
  );
};

export default withFormContext(NPIForm);
