import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps, Step, Stepper } from '@mui/material';
import { clone, indexOf, mergeRight, pick } from 'ramda';
import * as React from 'react';
import ActionButton from '../../../../../components/ActionButton';
import ConfirmationDialog from '../../../../../components/ConfirmationDialog';
import {
  Connector,
  IconChooser,
  StyledLabelText,
  StyledStepContent,
  StyledStepLabel,
} from '../../../../../components/EventStepper/components';
import { fm } from 'Components/FormatMessage';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import { Container, Item } from '../../../../../components/Grid';
import colors from '../../../../../config/theme/colors';
import { dialogActions, dialogCancel, dialogContent, dialogTitle } from '../../../../../config/theme/componentTheme';
import { createID } from '../../../../../utility/appendIDs';
import { exists, formatPartialDate, sortPartialDate } from 'neuro-utils';
import { getLatestGenerator } from '../../../utils';
import { generatorsWithAutoStimNightAndScheduledSettings, generatorsWithAutoStimSettings } from '../../config';
import { SettingFields } from './components';
import { isEmpty, isNil, omit, path } from 'Utility/ramdaReplacement';

const DialogPaper = (props: PaperProps): JSX.Element => <Paper square={true} {...props} />;

const SettingEvents = ({ formData, disabled }: IOwnProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const [editIndex, setEditIndex] = React.useState<number>(0);
  const [oldSettings, setOldSettings] = React.useState<Array<IVNSSetting> | undefined>(undefined);
  const [activeStep, setActiveStep] = React.useState<number>(-1);

  const openCloseDialog = (settingIndex?: number, cancel?: boolean) => (): void => {
    if (!dialogOpen) {
      setDialogOpen(true);
      if (!exists(settingIndex)) {
        if (isNil(formData.document?.settings) || isEmpty(formData.document?.settings)) {
          formData.onChange?.({
            settings: [
              {
                id: createID(),
                date: getLatestGenerator(formData.document)?.date,
                generator: getLatestGenerator(formData.document)?.id,
              },
            ],
          });
          setEditIndex(0);
        } else {
          const newSettings = JSON.parse(JSON.stringify(formData.document.settings));
          newSettings.unshift({
            id: createID(),
            date: getLatestGenerator(formData.document)?.date,
            generator: getLatestGenerator(formData.document)?.id,
          });
          formData.onChange?.({ settings: newSettings });
          setEditIndex(0);
        }
        setOldSettings(JSON.parse(JSON.stringify(formData.document.settings ?? [])));
      } else {
        setOldSettings(JSON.parse(JSON.stringify(formData.document.settings ?? [])));
        setEditIndex(settingIndex ?? 0);
      }
    }
    if (dialogOpen) {
      if (cancel) {
        formData.onChange?.({ settings: oldSettings });
      }
      setOldSettings(undefined);
      setDialogOpen(false);
    }
  };

  const openDeleteDialog = (settingIndex?: number) => (): void => {
    setDeleteDialogOpen(true);
    setEditIndex(settingIndex ?? 0);
  };

  const deleteCancelCallback = (): void => {
    setEditIndex(0);
    setDeleteDialogOpen(false);
  };

  const deleteConfirmCallback = (): void => {
    const newSettings = JSON.parse(JSON.stringify(formData.document.settings));
    formData.onChange?.({
      settings: newSettings.filter((_: IVNSSetting, index: number) => index !== editIndex),
    });
    setEditIndex(0);
    setDeleteDialogOpen(false);
  };

  const previousSettings =
    Array.isArray(formData.document.settings) &&
    JSON.parse(JSON.stringify(formData.document.settings))
      .sort((s1: IVNSSetting, s2: IVNSSetting) => sortPartialDate(s2.date, s1.date))
      .filter((setting: IVNSSetting, index: number, arr: IVNSSetting[]) => {
        const generator: string | undefined = path(['settings', editIndex, 'generator'], formData.document);
        const settingsMatch =
          generatorsWithAutoStimSettings.includes(path(['generator'], setting) ?? '') ===
            generatorsWithAutoStimSettings.includes(generator ?? '') &&
          generatorsWithAutoStimNightAndScheduledSettings.includes(path(['generator'], setting) ?? '') ===
            generatorsWithAutoStimNightAndScheduledSettings.includes(generator ?? '');
        return (
          index > indexOf(formData.document.settings?.find((_: IVNSSetting, i: number) => i === editIndex), arr) &&
          settingsMatch
        );
      });

  const copySettings = (): void => {
    const newSettings = JSON.parse(JSON.stringify(formData.document.settings));
    const latestPrevious =
      Array.isArray(previousSettings) && previousSettings.length > 0 ? previousSettings[0] : undefined;
    if (latestPrevious) {
      newSettings[editIndex] = mergeRight(
        pick(['id', 'date', 'generator'], newSettings[editIndex]),
        clone(omit(['id', 'date', 'generator'], latestPrevious)),
      );
      formData.onChange?.({ settings: newSettings });
    }
  };

  return (
    <React.Fragment>
      <FormRow title="vns.newSetting">
        <Dialog open={dialogOpen} fullWidth={true} maxWidth="lg" PaperComponent={DialogPaper}>
          <DialogTitle style={dialogTitle}>
            <Container>
              <Item xs={6}>{fm('vns.settings')}</Item>
              <Item xs={6} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                {Array.isArray(formData.document.settings) && previousSettings.length > 0 && (
                  <React.Fragment>
                    <ActionButton
                      text={'vns.copyPreviousSettings'}
                      onClick={copySettings}
                      width={20}
                      height={3}
                      fontSize={14}
                    />
                  </React.Fragment>
                )}
              </Item>
            </Container>
          </DialogTitle>
          <DialogContent style={dialogContent}>
            {dialogOpen && <SettingFields editIndex={editIndex} formData={formData} editing={true} />}
          </DialogContent>
          <DialogActions style={dialogActions}>
            <div style={dialogCancel} onClick={openCloseDialog(editIndex, true)}>
              {fm('general.cancel')}
            </div>
            <ActionButton
              text="general.accept"
              onClick={openCloseDialog(editIndex)}
              width={12}
              height={3}
              fontSize={16}
            />
          </DialogActions>
        </Dialog>
        <Container justifyContent="flex-start">
          <Item>
            <ActionButton
              text="vns.newSetting"
              onClick={openCloseDialog()}
              width={20}
              height={5}
              fontSize={18}
              disabled={disabled}
              disabledTooltip={fm('vns.addGeneratorFirst')}
            />
          </Item>
        </Container>
      </FormRow>
      {Array.isArray(formData.document?.settings) && formData.document.settings?.length > 0 && (
        <FormSection header="vns.latestSetting">
          <div style={{ height: '5rem', backgroundColor: colors.lightGray, display: 'flex', alignItems: 'center' }}>
            <div style={{ fontWeight: 600, marginLeft: '5rem' }}>
              {formatPartialDate(formData.document.settings?.[0]?.date)}
            </div>
          </div>
          <div>
            <div style={{ margin: '2rem 0 0 5rem', padding: '2rem' }}>
              <SettingFields
                formData={formData}
                editIndex={0}
                editing={false}
                openCloseDialog={openCloseDialog}
                openDeleteDialog={openDeleteDialog}
              />
            </div>
          </div>
        </FormSection>
      )}
      {Array.isArray(formData.document?.settings) && formData.document.settings?.length > 1 && (
        <FormSection header="vns.previousSettings">
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ margin: '2rem 0 0 0', padding: '0' }}
            connector={<Connector />}
          >
            {formData.document.settings?.slice(1).map(
              (d: IVNSSetting, index: number): JSX.Element => (
                <Step key={index} style={{ marginBottom: '2rem' }}>
                  <StyledStepLabel
                    StepIconComponent={IconChooser}
                    onClick={(): void => setActiveStep(index !== activeStep ? index : -1)}
                  >
                    <StyledLabelText>{formatPartialDate(d?.date)}</StyledLabelText>
                  </StyledStepLabel>
                  <StyledStepContent>
                    <div style={{ padding: '2rem' }}>
                      <SettingFields
                        formData={formData}
                        editIndex={index + 1}
                        editing={false}
                        openCloseDialog={openCloseDialog}
                        openDeleteDialog={openDeleteDialog}
                      />
                    </div>
                  </StyledStepContent>
                </Step>
              ),
            )}
          </Stepper>
        </FormSection>
      )}
      <ConfirmationDialog
        open={deleteDialogOpen}
        text={fm('general.reallyWantToDelete')}
        confirm={{ callback: deleteConfirmCallback }}
        cancel={{ callback: deleteCancelCallback }}
      />
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IVNS>;
  disabled: boolean;
}

export default SettingEvents;
