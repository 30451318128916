import { append, remove } from 'ramda';
import * as React from 'react';
import { styled } from '@mui/system';
import { OperationContext } from '../config';

import ActionButton from '../../../../components/ActionButton';
import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import { Container, Item } from '../../../../components/Grid';
import InputHandler from '../../../../components/InputHandler';

import colors from '../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../containers/FormContextHandler';
import { createID } from '../../../../utility/appendIDs';
import Complications from './Complications';
import CyberKnife from './CyberKnife';
import Disconnection from './Disconnection';
import EngelClassification from './EngelClassification';
import IntracranialImaging from './IntracranialImaging';
import Lesionectomy from './Lesionectomy';
import LocalResection from './LocalResection';
import Pad from './Pad';
import Thermocoagulation from './Thermocoagulation';
import { isNil } from 'Utility/ramdaReplacement';

const StyledDelete = styled('a')({
  fontSize: '1.6rem',
  fontWeight: 600,
  cursor: 'pointer',
  color: colors.primary,
  textTransform: 'uppercase',
  marginRight: '2rem',
});

const Operation = ({ type }: { type?: string }): JSX.Element => {
  switch (type) {
    case 'localResection':
      return <LocalResection />;
    case 'lesionectomy':
      return <Lesionectomy />;
    case 'disconnection':
      return <Disconnection />;
    case 'cyberKnife':
      return <CyberKnife />;
    case 'thermoCoagulation':
      return <Thermocoagulation />;
    case 'intracranialImaging':
      return <IntracranialImaging />;
    default:
      return <React.Fragment />;
  }
};

const addOperation = (onChange: IFormData<ISurgicalTreatment>['onChange'], d: ISurgicalTreatment): void => {
  onChange &&
    onChange({
      operations: append({ id: createID() } as TOperation, d.operations ?? []),
    });
};
const deleteOperation = (
  onChange: IFormData<ISurgicalTreatment>['onChange'],
  d: ISurgicalTreatment,
  id: string,
): void => {
  onChange &&
    onChange({
      operations:
        !isNil(d.operations) && d.operations.length > 0
          ? remove(
              d.operations?.indexOf(d.operations.find((op: TOperation) => op.id === id) as TOperation),
              1,
              d.operations,
            )
          : undefined,
    });
};

const typeOfOperationOnChange =
  (onChange: IFormData<ISurgicalTreatment>['onChange'], d: ISurgicalTreatment, i: number) =>
  (values: TOnChangeValues): void => {
    if (d.operations?.[i]) {
      const operations = [...d.operations];
      operations[i] = { ...operations?.[i], ...values };
      onChange && onChange({ operations });
    }
  };

const canNewOperationBeAdded = (o?: Array<IOperation>): boolean => {
  const operationsLengthIsZero = o === undefined || o.length === 0;
  if (operationsLengthIsZero) return true;

  const operationsLengthIsLessThanTwo = o && o.length < 2;
  const operationIsLocalResesction = o?.[0] && o[0].typeOfOperation === 'localResection';
  const operationIsLesionectomy = o?.[0] && o[0].typeOfOperation === 'lesionectomy';
  if (operationsLengthIsLessThanTwo && (operationIsLocalResesction || operationIsLesionectomy)) return true;

  return false;
};

const operationTypeOptions = (o: Array<IOperation>, index: number): Array<string> => {
  const firstType = o[0]?.typeOfOperation;
  const secondType = o[1]?.typeOfOperation;

  if (!o || o.length < 2)
    return [
      'localResection',
      'lesionectomy',
      'disconnection',
      'cyberKnife',
      'thermoCoagulation',
      'intracranialImaging',
    ];

  if (index === 0) {
    if (secondType) {
      if (secondType === 'lesionectomy') return ['localResection'];
      return ['lesionectomy'];
    }
    return ['localResection', 'lesionectomy'];
  }

  if (index === 1 && firstType) {
    if (firstType === 'lesionectomy') return ['localResection'];
    return ['lesionectomy'];
  }

  return ['localResection', 'lesionectomy'];
};

const SurgicalTreatmentForm = ({ formData, editing, view, fm }: IFormContext<ISurgicalTreatment>): JSX.Element => {
  const editingDoc = !!editing && !view?.viewing;
  const { document, onChange } = formData as IFormData<ISurgicalTreatment>;
  const operations = document.operations;

  const existingOperationTypes = (Array.isArray(operations) && operations.map((o) => o.typeOfOperation)) || [];

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="surgicalTreatment.date">
          <InputHandler type="PartialDate" editing={editingDoc} name="date" formData={formData} dateDefault="now" />
        </FormRow>
      </FormSection>

      {operations &&
        operations.length > 0 &&
        operations.map((o: TOperation, i: number) => (
          <React.Fragment key={o.id}>
            <FormSection header={'surgicalTreatment.operation.operation'}>
              <FormRow title="surgicalTreatment.operation.typeOfOperation.title">
                <Container>
                  <Item style={{ width: '25rem' }}>
                    <InputHandler
                      type="Radio"
                      name="typeOfOperation"
                      editing={editingDoc}
                      formData={{
                        document: { typeOfOperation: document.operations?.[i].typeOfOperation },
                        onChange: typeOfOperationOnChange(onChange, document, i),
                      }}
                      options={operationTypeOptions(operations, i)}
                      optionFormatter={(id: string | number): string =>
                        fm ? fm(`surgicalTreatment.operation.typeOfOperation.opts.${id}`) : id.toString()
                      }
                      placeholder="surgicalTreatment.operation.typeOfOperation.select"
                      dependentFieldsList={(): string[] => ['locations', 'areas', 'disconnections', 'coagulations']}
                    />
                  </Item>

                  {i === 1 ? (
                    <Item>
                      <StyledDelete onClick={(): void => deleteOperation(onChange, document, o.id)}>
                        {fm && fm('general.delete')}
                      </StyledDelete>
                    </Item>
                  ) : null}
                </Container>
              </FormRow>

              <OperationContext.Provider value={{ operationIndex: i }}>
                <Operation type={o.typeOfOperation} />
              </OperationContext.Provider>
            </FormSection>
          </React.Fragment>
        ))}

      {canNewOperationBeAdded(operations) && (
        <FormSection header={'surgicalTreatment.operation.operation'}>
          <FormRow title="surgicalTreatment.add">
            <ActionButton
              text={'surgicalTreatment.operation.add'}
              onClick={(): void => addOperation(onChange, document)}
              width={20}
              height={5}
              fontSize={18}
            />
          </FormRow>
        </FormSection>
      )}

      {(existingOperationTypes.includes('localResection') || existingOperationTypes.includes('lesionectomy')) && (
        <FormSection header="surgicalTreatment.pad.header">
          <Pad />
        </FormSection>
      )}

      <FormSection header="surgicalTreatment.complications.title">
        <Complications />
      </FormSection>

      <FormSection header="surgicalTreatment.engelClassification.title">
        <EngelClassification />
      </FormSection>
    </React.Fragment>
  );
};

export default withFormContext(SurgicalTreatmentForm);
