import * as React from 'react';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';

const optionFormatter = (id: string | number, p: string, fm?: (id: string) => string): string =>
  fm ? fm(`surgicalTreatment.pad.${p}.${id}`) : '';

const stepLabelText = (d: IPad, fm?: (id: string) => string): string =>
  fm ? fm(`surgicalTreatment.pad.findings.opts.${d.findings}`) : '';

const StepContent = ({ d, fm }: { d: IPad; fm?: (id: string) => string }): JSX.Element => {
  return (
    <React.Fragment>
      <StepperHeaderValuePair
        header={<>{fm ? fm('surgicalTreatment.pad.findings.title') : ''}</>}
        value={fm ? fm(`surgicalTreatment.pad.findings.opts.${d.findings}`) : '-'}
      />

      {d.subType && d.findings && ['dnt', 'hippocampalSclerosis', 'fcd'].includes(d.findings) ? (
        <StepperHeaderValuePair
          header={<>{fm ? fm('surgicalTreatment.pad.subType.title') : ''}</>}
          value={fm ? fm(`surgicalTreatment.pad.subType.opts.${d.subType}`) : '-'}
        />
      ) : undefined}
      {d.otherInfo && d.findings && ['maligniGlioma', 'otherTumor', 'otherFinding'].includes(d.findings) ? (
        <StepperHeaderValuePair
          header={<>{fm ? fm(`surgicalTreatment.pad.otherInfo.${d.findings}Title`) : ''}</>}
          value={d.otherInfo}
        />
      ) : undefined}
    </React.Fragment>
  );
};

const subTypeOpts = (findings: string): Array<TPadFindingsSubTypes> =>
  findings === 'dnt'
    ? ['normal', 'complex']
    : findings === 'hippocampalSclerosis'
      ? ['ilaeType1', 'ilaeType2', 'ilaeType3', 'ilaeTypeGliosisOnly', 'unknown']
      : findings === 'fcd'
        ? [
            'typeIUndefined',
            'typeIa',
            'typeIb',
            'typeIc',
            'typeIIa',
            'typeIIb',
            'typeIII',
            'typeIIIa',
            'typeIIIb',
            'typeIIIc',
            'typeIIId',
            'mMcd',
            'unknown',
          ]
        : [];

const EditingElement = ({
  formData,
  editing,
  fm,
}: {
  formData: IFormData<IPad>;
  editing: boolean;
  fm?: (id: string) => string;
}): JSX.Element => {
  const document = formData.document;
  const findings = document?.findings;
  return (
    <React.Fragment>
      <FormRow title="surgicalTreatment.pad.findings.title">
        <InputHandler
          type="Select"
          name="findings"
          editing={editing}
          formData={formData}
          options={[
            'unrepresentativeSample',
            'nonDiagnosticChanges',
            'reactiveChanges',
            'glialScarChange',
            'ganglioglioma',
            'dnt',
            'pxa',
            'maligniGlioma',
            'hyptahalamushamartoma',
            'angiocentricGlioma',
            'philocyticAstrocytoma',
            'otherTumor',
            'hippocampalSclerosis',
            'fcd',
            'tuberousSclerosisFittingTuber',
            'hemimegalencephaly',
            'heterotopy',
            'polymicrogyria',
            'rasmunssenEncephalitisFittingInflammatoryFinding',
            'otherInflammatoryFinding',
            'cavernousHemangioma',
            'avm',
            'capillaryTelangiectasia',
            'strugeWberSyndromFittingAngiomatosis',
            'otherFinding',
          ]}
          optionFormatter={(id: string | number): string => optionFormatter(id, 'findings.opts', fm)}
          placeholder="surgicalTreatment.pad.findings.placeholder"
        />
      </FormRow>
      {findings && ['dnt', 'hippocampalSclerosis', 'fcd'].includes(findings) ? (
        <FormRow title="surgicalTreatment.pad.subType.title">
          <InputHandler
            type={findings === 'dnt' ? 'Radio' : 'Select'}
            name="subType"
            editing={editing}
            formData={formData}
            options={findings ? subTypeOpts(findings) : []}
            optionFormatter={(id: string | number): string => optionFormatter(id, 'subType.opts', fm)}
            placeholder="surgicalTreatment.pad.subType.title"
          />
        </FormRow>
      ) : undefined}
      {findings && ['maligniGlioma', 'otherTumor', 'otherFinding'].includes(findings) ? (
        <FormRow title={`surgicalTreatment.pad.otherInfo.${findings}Title`}>
          <InputHandler
            type="TextArea"
            name="otherInfo"
            editing={editing}
            formData={formData}
            placeholder={`surgicalTreatment.pad.otherInfo.${findings}Placeholder`}
          />
        </FormRow>
      ) : undefined}
    </React.Fragment>
  );
};

const PadFindings = ({ formData, editing, view, fm }: IFormContext): JSX.Element => {
  const editingDoc = !!editing && !view?.viewing;
  const document = formData?.document as ISurgicalTreatment;
  const pads = document.pads;

  const path = 'surgicalTreatment.pad.findings';

  return (
    <EventStepper
      name="pads"
      formData={formData as IFormData<ISurgicalTreatment>}
      stepLabelText={(d: IPad): string => stepLabelText(d, fm)}
      stepContent={(d: IPad): JSX.Element => <StepContent d={d} fm={fm} />}
      addNewTextHeader={`${path}.new`}
      addNewTextButton={`${path}.add`}
      previousEventsTextHeader={`${path}.previous`}
      editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
        const pad = pads?.[index];
        return (
          <React.Fragment>
            {pad ? (
              <EditingElement formData={{ document: pad, onChange: onChange }} editing={editingDoc} fm={fm} />
            ) : undefined}
          </React.Fragment>
        );
      }}
    />
  );
};

export default withFormContext(PadFindings);
