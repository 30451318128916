/**
 * Handles loading toolbar and document form with necessary information and functions for forms
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';

import Toolbar, { IAdditionalProps as IToolBarAdditional } from '../../components/Toolbar';

import NavigationBlocker from './NavigationBlocker';

import { actions as actionsDocument, TCreationPromiseType } from '../../store/documents/actions';
import { actions as actionsForm } from '../../store/form';
import { getEnabledDocuments } from '../../utility/randomUtil';
import { INeuroDocument } from 'neuro-data-structures';
import { FormattedMessage } from 'react-intl';
import EditingLockedContent from 'Containers/EditingLockedContentContext';
import { path } from 'Utility/ramdaReplacement';

const getDocumentType = (id: string, documents: Array<{ _id: string; _type: string }>): string | undefined => {
  return (
    path(
      [0, '_type'],
      documents.filter((d) => d._id === id),
    ) || undefined
  );
};

type Props = IStateFromProps &
  IDispatchFromProps &
  IOwnProps & {
    router: { navigate: any; location?: { state?: { relocate: string } }; params: Readonly<Params<string>> };
  };

class FormEditingHandler extends React.Component<Props, IOwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      timer: undefined,
      formData: undefined,
      documentLoaded: false,
      editing: false,
      viewing: false,
      subDocuments: undefined,
      loading: {
        saving: false,
        cancelling: false,
      },
    };
  }

  getDocument = (id: string) => this.props.documents.find((d: { _id: string }) => d._id === id);

  // When edit/add new button is pressed, name is used when document type differs from page name (eg. updrs_iii)
  public startEdit = (document: { _id?: string }, name?: string, initialFormData?: Record<string, any>) => (): void => {
    if (!this.state.editing) {
      //this.setState({ editing: true });
      if (document._id) {
        this.props
          .createCommit(
            {
              name: getDocumentType(document._id, this.props.documents) as string,
              id: document._id,
            },
            this.getDocument(document._id), // Current data
          )
          .then((commit: string | { _cid?: string }) => {
            if (document._id && typeof commit === 'object' && commit._cid) {
              this.loadFormValues(document._id);
              this.props.setEditing(getDocumentType(document._id, this.props.documents) as string, document._id);
            }
          })
          .catch(() => {
            return;
          });
      } else {
        // No id which means new document
        this.props
          .createDocument(name || this.props.name, initialFormData)
          .then(() => this.setState({ editing: true }))
          .catch(() => {
            return;
          });
      }
    }
  };

  // When view button is pressed
  public startView = (document: { _id?: string }) => (): void => {
    if (!this.state.viewing) {
      this.setState({ viewing: true });
      if (document._id) {
        this.props.setViewing(getDocumentType(document._id, this.props.documents) as string, document._id);
        this.loadFormValues(document._id);
      }
    }
  };

  // When view button is pressed
  public endView = (id: string) => (): void => {
    this.props.clearFormValues(id);
    this.props.unsetViewing(id);
    this.setState({ formData: undefined, documentLoaded: false, viewing: false });
  };

  // When save button is pressed
  public saveDraft = (id: string) => async (): Promise<void> => {
    this.setState({
      loading: { saving: true, cancelling: false },
    });
    await this.props.closeCommit(
      { name: getDocumentType(id, this.props.documents) as string, id },
      undefined,
      this.state.subDocuments,
    );
    this.props.clearFormValues(id);
    this.props.unsetEditing(id);

    this.setState({
      timer: undefined,
      formData: undefined,
      documentLoaded: false,
      editing: false,
      subDocuments: undefined,
      loading: {
        saving: false,
        cancelling: false,
      },
    });
  };
  // When cancel button is pressed
  public cancelDraft = (id: string) => async (): Promise<void> => {
    this.setState({ loading: { saving: false, cancelling: true } });
    await this.props.deleteCommit(
      { name: getDocumentType(id, this.props.documents) as string, id },
      this.state.subDocuments,
    );
    this.props.clearFormValues(id);
    this.props.unsetEditing(id);

    this.setState({
      timer: undefined,
      formData: undefined,
      documentLoaded: false,
      editing: false,
      subDocuments: undefined,
      loading: {
        saving: false,
        cancelling: false,
      },
    });
  };
  public formValuesChange = (newValues: TOnChangeValues): void => {
    this.setState((state: IOwnState) => {
      state.timer && clearTimeout(state.timer);
      return { timer: setTimeout(this.patchIfChanges, 3000) };
    });
    this.props.updateFormValues(this.props.editingDocument.id, newValues);
  };

  public patchIfChanges = (): void => {
    if (this.props.formData !== this.state.formData) {
      // If there are changes in the form
      if (this.props.editingDocument) {
        // Editingdocument disappears if form is saved before patch is triggered
        this.updateDocument({
          name: getDocumentType(this.props.editingDocument.id, this.props.documents) as string,
          id: this.props.editingDocument.id,
        });
        // deepcode ignore ReactNextState: Needs rework to fix
        this.setState({ formData: this.props.formData });
      }
    }
  };

  public loadFormValues = (id: string): void => {
    if (this.props.documents && !this.state.documentLoaded) {
      this.props.loadFormValues(this.getDocument(id));
      this.setState({ documentLoaded: true });
    }
  };

  public updateDocument = (document: { name: string; id: string }): void => {
    this.props.updateDocument(document);
  };

  public componentDidMount = (): void => {
    // When component is mounted, check if theres editing going on
    // This is needed especially if page is refreshed somewhere else and we need to load editing documents when this page is loaded
    if (this.props.documents && this.props.editingDocument) {
      this.setState({ editing: true });
      this.loadFormValues(this.props.editingDocument.id);
    }
    if (this.props.documents && this.props.viewingDocument) {
      this.setState({ viewing: true });
      this.loadFormValues(this.props.viewingDocument.id);
    }
  };

  public componentDidUpdate = (prevProps: Props): void => {
    // Check if editing id and documents appear (undefined => defined) when component is updated
    // This will load the documents when page is refreshed
    if (
      !this.state.editing &&
      (!prevProps.editingDocument || !prevProps.documents) &&
      this.props.documents &&
      this.props.editingDocument
    ) {
      this.setState({ editing: true });
      this.loadFormValues(this.props.editingDocument.id);
    } else if (this.props.router.location?.state?.relocate) {
      // Relocate to different page and create new document
      this.props.router.navigate(`/${this.props.router.location.state?.relocate}`, undefined);
      this.startEdit({})();
    }
    if (
      !this.state.viewing &&
      (!prevProps.viewingDocument || !prevProps.documents) &&
      this.props.documents &&
      this.props.viewingDocument
    ) {
      this.setState({ viewing: true });
      this.loadFormValues(this.props.viewingDocument.id);
    }
  };

  public componentWillUnmount = (): void => {
    if (this.props.editingDocument) {
      // Update (save) the document when component unmounts
      this.updateDocument(this.props.editingDocument);
    }
    if (this.props.viewingDocument) {
      this.props.unsetViewing(this.props.viewingDocument.id);
    }
    this.setState({ timer: undefined, formData: undefined, documentLoaded: false, subDocuments: undefined });
  };

  public setSubDocuments = (subDocuments: { name: string; id: string }[]): void => this.setState({ subDocuments });

  public render(): JSX.Element {
    const documentHasNewerSavedCommits = (): boolean | null => {
      const thisReduxDoc = this.props.reduxDocuments.find((rd) => rd.documentId === this.props.editingDocument?.id);
      const myUnfinishedCommit = thisReduxDoc?.commits.find((c) => c.creatorId === this.props.userId && !c.commitDate);
      if (!myUnfinishedCommit) return null;
      return (
        thisReduxDoc?.commits.some((commits) => (commits.commitDate || 0) >= myUnfinishedCommit.createDate) || false
      );
    };

    const disabledProps = documentHasNewerSavedCommits()
      ? { saveButtonDisabled: true, saveButtonDisabledTooltip: <FormattedMessage id="general.newerDateExists" /> }
      : {};

    return (
      <EditingLockedContent currentPage={this.props.name}>
        <Toolbar
          current={this.props.name}
          editing={this.props.editingDocument && this.props.editingDocument.id}
          saveDraft={this.saveDraft}
          cancelDraft={this.cancelDraft}
          view={{
            viewing: this.props.viewingDocument?.id,
            endView: this.endView,
          }}
          spinnerCancelButton={this.state.loading.cancelling}
          spinnerEnabled={this.state.loading.saving}
          {...{
            ...this.props.toolbarProps,
            saveButtonCallback: this.props.toolbarProps?.saveButtonCallback?.(
              this.props.editingDocument?.id
                ? {
                    ...(this.props.formData[this.props.editingDocument.id] as object),
                    _id: this.props.editingDocument.id,
                  }
                : undefined,
            ),
          }}
          {...disabledProps}
        />
        {this.props.editingDocument && !this.state.documentLoaded ? null : ( // Dont render form before the document has been loaded
          <NavigationBlocker blocked={!!this.props.editingDocument}>
            {this.props.children(
              this.props.editingDocument && this.props.editingDocument?.id,
              this.startEdit,
              {
                document: this.props.editingDocument?.id // If editing
                  ? path(['formData', this.props.editingDocument?.id], this.props) || {}
                  : this.props.viewingDocument?.id // If viewing
                    ? path(['formData', this.props.viewingDocument?.id], this.props) || {}
                    : {},
                onChange: this.props.editingDocument?.id
                  ? this.formValuesChange
                  : (): void => {
                      return;
                    },
              },
              {
                // Give children viewing document and tools
                viewing: this.props.viewingDocument?.id,
                // Document here is kinda useless as the viewing document is already injected into formData, but this is still used in some routes
                document: path(['formData', this.props.viewingDocument?.id], this.props) || {},
                startView: this.startView,
              },
              this.saveDraft,
              this.setSubDocuments,
            )}
          </NavigationBlocker>
        )}
      </EditingLockedContent>
    );
  }
}

interface IOwnState {
  timer?: NodeJS.Timeout;
  formData?: TAnyObject;
  documentLoaded: boolean;
  editing: boolean;
  viewing: boolean;
  subDocuments?: { name: string; id: string }[];
  loading: {
    saving: boolean;
    cancelling?: boolean;
  };
}

interface IEditingDocument {
  id: string;
  name: string;
}

type TSubDocuments = { name: string; id: string }[];

interface IStateFromProps {
  editingDocument: IEditingDocument;
  viewingDocument: IEditingDocument;
  formData: TAnyObject;
  reduxDocuments: INeuroDocument[];
  userId: string;
}

interface IDispatchFromProps {
  clearFormValues: (id: string) => void;
  loadFormValues: (document: { _id: string }) => void;
  updateFormValues: (id: string, values: TOnChangeValues) => void;

  setEditing: (name: string, id: string) => void;
  unsetEditing: (id: string) => void;
  setViewing: (name: string, id: string) => void;
  unsetViewing: (id: string) => void;

  createDocument: (name: string, initialFormData?: Record<string, any>) => Promise<TCreationPromiseType>;
  createCommit: (editingDocument: IEditingDocument, oldData?: IControlProps) => Promise<TCreationPromiseType>;
  updateDocument: (editingDocument: IEditingDocument) => Promise<TCreationPromiseType>;
  closeCommit: (
    editingDocument: IEditingDocument,
    formData?: TAnyObject,
    subDocuments?: TSubDocuments,
  ) => Promise<void>;
  deleteCommit: (editingDocument: IEditingDocument, subDocuments?: TSubDocuments) => Promise<void>;
}

// Return match name, unless theres subTypes then match all subTypes
const editingPredicate = (
  d: { name: string },
  props: { name: string },
  session: ISessionStore,
  document: IControlProps & any,
): boolean => {
  const enabledName = getEnabledDocuments(session).find(
    (name: { name: string; subTypes?: string[] }) => name.name === props.name,
  );
  if (enabledName && enabledName.subTypes) {
    // Filter specific documents based on filtering function
    const subTypeFilter =
      enabledName.subTypesFiltering && enabledName.subTypesFiltering[d.name]
        ? enabledName.subTypesFiltering[d.name](document)
        : true;
    return enabledName.subTypes.includes(d.name) && subTypeFilter;
  } else {
    return d.name === props.name;
  }
};

const mapStateToProps = (state: any, props: IOwnProps): IStateFromProps => {
  const sortedAndMergedDocument = (docID: string) =>
    (props.documents || []).find((doc: IControlProps) => doc._id === docID);
  return {
    editingDocument: state.form.editingDocuments.filter((d: IEditingDocument) =>
      editingPredicate(d, props, state.session, sortedAndMergedDocument(d.id)),
    )[0],
    viewingDocument: state.form.viewingDocuments.filter((d: IEditingDocument) =>
      editingPredicate(d, props, state.session, sortedAndMergedDocument(d.id)),
    )[0],
    formData: state.form.formData,
    reduxDocuments: state.documents.documents || [],
    userId: state.session.data?.useruuid || '',
  };
};

const mapDispatchToProps = (dispatch: any): any => ({
  clearFormValues: (id: string): void => dispatch(actionsForm.clearFormValues(id)),
  loadFormValues: (document: TAnyObject & IControlProps): void =>
    dispatch(actionsForm.checkForLoadFormValues(document)),
  updateFormValues: (id: string, values: TOnChangeValues): void => dispatch(actionsForm.updateFormValues(id, values)),

  setEditing: (name: string, id: string): void => dispatch(actionsForm.setEditing(name, id)),
  unsetEditing: (id: string): void => dispatch(actionsForm.unsetEditing(id)),
  setViewing: (name: string, id: string): void => dispatch(actionsForm.setViewing(name, id)),
  unsetViewing: (id: string): void => dispatch(actionsForm.unsetViewing(id)),

  createDocument: (name: string, initialFormData?: TAnyObject & IControlProps): Promise<TCreationPromiseType> =>
    dispatch(actionsDocument.createDocument(name, false, initialFormData)),
  createCommit: (
    editingDocument: IEditingDocument,
    formData: TAnyObject & IControlProps,
  ): Promise<TCreationPromiseType> => dispatch(actionsDocument.createCommit(editingDocument, formData)),
  updateDocument: (editingDocument: IEditingDocument): Promise<TCreationPromiseType> =>
    dispatch(actionsDocument.updateDocumentData(editingDocument)),
  closeCommit: (
    editingDocument: IEditingDocument,
    formData: TAnyObject & IControlProps,
    subDocuments?: TSubDocuments,
  ): Promise<void> => dispatch(actionsDocument.closeCommit(editingDocument, formData, subDocuments)),
  deleteCommit: (editingDocument: IEditingDocument, subDocuments?: TSubDocuments): void =>
    dispatch(actionsDocument.deleteCommit(editingDocument, subDocuments)),
});

export type ISaveDraft = InstanceType<typeof FormEditingHandler>['saveDraft'];

interface IOwnProps {
  name: string;
  documents: any;
  children: (
    editing: string,
    startEdit: (
      document: { _id?: string },
      name?: string,
      initialFormData?: Record<string, any>,
    ) => (e: React.MouseEvent) => void,
    formData: IFormData,
    view?: {
      viewing: string;
      document: any;
      startView: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
    },
    saveDraft?: (id: string) => () => Promise<void>,
    setSubDocuments?: (subDocuments: TSubDocuments) => void,
  ) => JSX.Element;
  toolbarProps?: { saveButtonCallback?: (document?: IControlProps & any) => () => void } & Omit<
    IToolBarAdditional,
    'saveButtonCallback'
  >;
}

/** withRouter replacement for react-router-dom */
function withRouter(Component: any) {
  function ComponentWithRouterProp(props: IOwnProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter(
  connect<IStateFromProps, IDispatchFromProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(FormEditingHandler),
);
