import { MenuItem, Select, SelectProps } from '@mui/material';
import * as React from 'react';
import { assertCapabilities, useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import PlatformCapabilities from '../../../../../config/capabilities';
import { ICapabilityContextProps, withCapabilities } from '../../../../../containers/CapabilityHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { RootState } from '../../../../../store';
import { actions } from '../../../../../store/form';
import { createID } from '../../../../../utility/appendIDs';
import { isIDiagnosis, isPlatform, isString } from '../../../../../utility/typeGuards';
import { dxCategories, dmdDiagnoses, smaDiagnoses, allDiagnoses } from '../../../utils/definitions';
import { diagnosisOptionsFormatter, getAddableDiagnoses, optionFormatter } from '../../../utils/functions';
import DMDform from '../DMDForm';
import MsType from '../MSForm/MsType';
import SMAform from '../SMAForm';
import MGragisFormofDisease from '../MgravisForm/FormOfDisease';
import { isEmpty } from 'Utility/ramdaReplacement';

const DiagnosisForm = ({
  documents = [],
  editing,
  formData,
  setDiagnosisSelected,
  capabilityGroups = {},
  fm,
}: IDiagnosisFormProps): JSX.Element => {
  // Load available (for current center/user) platforms from state. For local env, remember to modify vault-core user generation
  const platforms = useSelector((s: RootState) => s.session.data?.platforms || []);
  const dispatch = useDispatch();
  const docs = documents.filter(isIDiagnosis);

  // define allowed scope
  const addableDiagnoses = getAddableDiagnoses({
    docs,
    platforms: platforms.filter(isPlatform),
  });
  /** Category is relevant if some of its diagnoses are addable. Also check for NMOSD capabilities to determine if the category needs to be included. */
  const isLicensedNMOSD = assertCapabilities([PlatformCapabilities.NMOSD_CAPABILITES], capabilityGroups);
  const relevantCategories = dxCategories.filter(
    (c) =>
      (!isLicensedNMOSD ? c && c.title !== 'diagnosis.opts.NMOSD' : c) &&
      c.values.some((v) => addableDiagnoses.includes(v)),
  );

  // diagnosis category menu state
  const [selectedDxCategory, setSelectedDxCategory] = React.useState<ICategoryOptions['title']>('');

  // diagnosis menu state
  const [selectedDx, setSelectedDx] = React.useState<TDiagnosis | undefined>(undefined);
  const [dxMenuOpen, setDxMenuOpen] = React.useState<boolean>(false);
  const [dxOpts, setDxOpts] = React.useState<TDiagnosis[]>(addableDiagnoses);

  // control handlers
  const menuOpenHandler = (): void => setDxMenuOpen(true);
  const menuCloseHandler = (): void => setDxMenuOpen(false);

  const onChangeValues = (e: TOnChangeValues): void => {
    const name = Object.keys(e)[0];
    const value = e[name];
    if (editing) {
      if (Object.keys(e).length > 0) {
        dispatch(actions.updateFormValuesBatch(e, editing));
      } else {
        dispatch(actions.updateFormValues(editing, { [name]: value }));
      }
    }
  };

  const onChangeDiagnosis = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    const thisValues = {
      diagnosis: value,
      typeOfDisease: value === 'G35' ? [{ date: formData?.document?.date, id: createID() } as IMSType] : undefined,
      formOfDisease:
        value === 'G70.0' ? [{ date: formData?.document?.date, id: createID() } as IMGravisFormOfDisease] : undefined,
    };

    dispatch(actions.updateFormValuesBatch(thisValues, editing ?? ''));

    setSelectedDx(value as TDiagnosis);
  };

  const onChangeDxGroup: SelectProps['onChange'] = (event) => {
    // validate data
    const { value: categoryTitle } = event.target;
    if (!isString(categoryTitle)) return;

    // update selected category
    setSelectedDxCategory(categoryTitle);

    // update selectable diagnoses
    const dxsInSelectedCateg = dxCategories.find((c) => c.title === categoryTitle)?.values ?? [];
    const allowedInCateg = dxsInSelectedCateg.filter((dx) => {
      if (categoryTitle === 'diagnosis.opts.KEO' && isLicensedNMOSD)
        return addableDiagnoses.filter((d) => d !== 'G36.0').includes(dx);
      return addableDiagnoses.includes(dx);
    });
    setDxOpts(allowedInCateg);
    if (!allowedInCateg.includes(selectedDx as TDiagnosis)) {
      if (editing) dispatch(actions.updateFormValues(editing, { diagnosis: undefined }));

      setSelectedDx(undefined);
    }
  };

  const renderValueDxGroup: SelectProps['renderValue'] = (v): React.ReactNode => {
    if (!isString(v) || v === '') return fm('diagnosis.chooseDxGroup');
    const localizationKey = dxCategories.find((c) => c.title === v)?.localization;
    return localizationKey ? fm(localizationKey) : 'localization missing';
  };

  // automatically toggle diagnosis menu or select a diagnosis
  React.useEffect(() => {
    // skip auto-operations if the diagnosis is invalid
    if (formData?.document.diagnosis && !allDiagnoses.includes(formData?.document.diagnosis)) {
      return;
    }

    // auto-select diagnosis category if diagnosis is selected but category is not
    if (formData?.document.diagnosis && (!selectedDxCategory || isEmpty(selectedDxCategory))) {
      const dxCategory =
        formData?.document.diagnosis === 'G36.0' && isLicensedNMOSD
          ? { title: 'diagnosis.opts.NMOSD' }
          : dxCategories.find((c) => c.values.some((v) => v === formData?.document.diagnosis));
      setSelectedDxCategory(dxCategory ? dxCategory.title : '');
      // update selectable diagnoses
      const dxsInSelectedCateg =
        dxCategories.find((c) => c.title === (dxCategory ? dxCategory.title : ''))?.values ?? [];
      const allowedInCateg = dxsInSelectedCateg.filter((dx) => addableDiagnoses.includes(dx));
      setDxOpts(allowedInCateg);
      return;
    }

    // detect if there is a mismatch between diagnosis and diagnosis category and empty diagnosis if needed
    if (
      selectedDxCategory &&
      formData?.document.diagnosis &&
      !dxCategories.find(
        (c) => c.title === selectedDxCategory && c.values.some((v) => v === formData?.document.diagnosis),
      )
    ) {
      if (editing) dispatch(actions.updateFormValues(editing, { diagnosis: undefined }));

      setSelectedDx(undefined);
    }

    // skip effect if set diagnosis is within options
    if (dxOpts.includes(formData?.document.diagnosis)) return;

    // auto-open menu...
    if (!formData?.document.diagnosis && !selectedDx && selectedDxCategory && dxOpts.length > 1) setDxMenuOpen(true);
    // ...or auto-select a diagnosis
    else if (dxOpts.length === 1) {
      const values = {
        // onChangeDiagnosis does not cover this part of the code as MS only has one diagnosis
        diagnosis: dxOpts[0],
        typeOfDisease:
          dxOpts[0] === 'G35' ? [{ date: formData?.document?.date, id: createID() } as IMSType] : undefined,
        formOfDisease:
          dxOpts[0] === 'G70.0'
            ? [{ date: formData?.document?.date, id: createID() } as IMGravisFormOfDisease]
            : undefined,
      };
      if (editing) {
        dispatch(actions.updateFormValues(editing, values));
        setDiagnosisSelected && setDiagnosisSelected(true);
      }
    }
  }, [
    addableDiagnoses,
    dispatch,
    dxOpts,
    editing,
    formData?.document.diagnosis,
    selectedDx,
    selectedDxCategory,
    setDiagnosisSelected,
  ]);

  // track selection of diagnosis and enable/disable save button accordingly
  React.useEffect(() => {
    if (setDiagnosisSelected) {
      !formData.document.diagnosis ? setDiagnosisSelected(false) : setDiagnosisSelected(true);
    }
    return (): void => {
      if (setDiagnosisSelected) {
        setDiagnosisSelected(true);
      }
    };
  }, [formData.document.diagnosis, setDiagnosisSelected]);

  return (
    <React.Fragment>
      <div style={{ marginBottom: '5rem' }} />
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="date"
            formData={{ document: formData.document, onChange: onChangeValues } as TStateFormData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title="diagnosis.dxGroup">
          <div style={{ maxWidth: '20rem' }}>
            <Select
              name="dxGroup"
              onChange={onChangeDxGroup}
              value={selectedDxCategory}
              displayEmpty
              renderValue={renderValueDxGroup}
              fullWidth
            >
              {relevantCategories.map((o) => (
                <MenuItem key={o.title} value={o.title}>
                  {o?.localization ? fm(o.localization) : 'localization missing'}
                </MenuItem>
              ))}
            </Select>
          </div>
        </FormRow>
        <FormRow title="diagnosis.diagnosis">
          <InputHandler
            type="Select"
            editing={!!editing}
            name="diagnosis"
            formData={{ document: formData?.document, onChange: onChangeDiagnosis }}
            options={dxOpts}
            optionFormatter={diagnosisOptionsFormatter}
            placeholder={'diagnosis.chooseDiagnosis'}
            open={dxMenuOpen}
            onOpen={menuOpenHandler}
            onClose={menuCloseHandler}
          />
        </FormRow>
        {formData?.document.diagnosis === 'unspecifiedDiagnosis' && (
          <FormRow title="diagnosis.unspecified.details">
            <InputHandler
              type="TextArea"
              editing={!!editing}
              name="unspecifiedDetails"
              formData={{ document: formData.document, onChange: onChangeValues } as TStateFormData}
              placeholder="diagnosis.unspecified.detailsPlaceholder"
            />
          </FormRow>
        )}
        {formData?.document.diagnosis === 'G35' && (
          <MsType
            editing={editing}
            formData={{ document: formData.document, onChange: onChangeValues } as TStateFormData}
            newDiagnosis={isEmpty(documents.filter((document) => document.diagnosis === 'G35'))}
          />
        )}
        {['G36.9', 'G37.9', 'G36.0'].includes(formData?.document.diagnosis) &&
          selectedDxCategory === 'diagnosis.opts.KEO' && (
            <FormSection>
              <FormRow title="diagnosis.keoType.title">
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="keoType"
                  formData={{ document: formData.document, onChange: onChangeValues } as TStateFormData}
                  options={['monosymptomatic', 'polysymptomatic']}
                  optionFormatter={optionFormatter('keoType.opts')}
                />
              </FormRow>
            </FormSection>
          )}
        {formData?.document.diagnosis === 'G70.0' && (
          <MGragisFormofDisease
            editing={editing}
            formData={{ document: formData.document, onChange: onChangeValues } as TStateFormData}
            newDiagnosis={isEmpty(documents.filter((document) => document.diagnosis === 'G70.0'))}
          />
        )}
      </FormSection>
      {smaDiagnoses.includes((formData?.document as IDiagnosis).diagnosis as TSMADiagnosis) && (
        <SMAform
          editing={editing}
          formData={{ document: formData.document, onChange: onChangeValues } as TStateFormData}
        />
      )}
      {dmdDiagnoses.includes((formData?.document as IDiagnosis).diagnosis as TDMDDiagnosis) && (
        <DMDform
          editing={editing}
          formData={{ document: formData.document, onChange: onChangeValues } as TStateFormData}
        />
      )}
    </React.Fragment>
  );
};

type TStateFormData = IFormData<IDiagnosis>;

interface IDiagnosisFormProps extends IFormContext, ICapabilityContextProps {
  setDiagnosisSelected?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default withFormContext(withCapabilities(DiagnosisForm));
