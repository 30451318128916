import * as React from 'react';
import { OperationContext } from '../../config';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { isNil, path } from 'Utility/ramdaReplacement';

const DisconnectionContext: React.Context<{
  formData?: IFormData<TDisconnection>;
  editing?: boolean;
  fm?: (id: string) => string;
  locPath?: string;
}> = React.createContext({});

const stepLabelText = (d: TDisconnection, fm?: (id: string) => string): string =>
  fm && d.type ? fm(`surgicalTreatment.disconnection.disconnections.type.opts.${d.type}`) : '';

const StepContentContext: React.Context<{ d?: TDisconnection; fm?: (id: string) => string }> = React.createContext({});

const DisconnectionContent = (): JSX.Element => (
  <StepContentContext.Consumer>
    {({ d, fm }): JSX.Element => {
      const locPath = `surgicalTreatment.disconnection.disconnections.${d?.type}`;
      const keys: Array<
        | keyof IBlockDisconnetion
        | keyof ISkullsotomy
        | keyof IHemispherectomy
        | keyof IFunctionalPartiaHemispherectomy
        | keyof IMultipleSubpialTransection
        | keyof IHypothalamicHamartoma
        | keyof IOtherDisconnection
      > = ['block', 'disconnection', 'scope', 'side', 'location'];
      return (
        <React.Fragment>
          {keys.map((k) => (
            <>
              {d && k in d && path([k], d) ? (
                <StepperHeaderValuePair
                  header={<>{fm ? fm(`${locPath}.${k}.title`) : ''}</>}
                  value={fm ? fm(`${locPath}.${k}.opts.${path([k], d)}`) : ''}
                />
              ) : (
                ''
              )}
            </>
          ))}
          {d && 'otherInfo' in d && d.otherInfo ? (
            <StepperHeaderValuePair header={<>{fm ? fm(`${locPath}.otherInfo.title`) : ''}</>} value={d.otherInfo} />
          ) : (
            ''
          )}
        </React.Fragment>
      );
    }}
  </StepContentContext.Consumer>
);

const optionFormatter = (id: string | number, p: string, fm?: (id: string) => string): string =>
  fm ? fm(`surgicalTreatment.disconnection.${p}.${id}`) : '';

interface ISettingsSchema {
  inputType: 'Radio' | 'Select' | 'TextArea';
  name: string;
  options?: Array<string>;
  dependedFieldsList?: () => string[];
}

const ChooseInputHandler = ({ settings, type }: { settings: ISettingsSchema; type: string }): JSX.Element => {
  return (
    <DisconnectionContext.Consumer>
      {({ editing, formData, fm, locPath }): JSX.Element => {
        const name = settings.name;
        const localizationPath = `${locPath}.${type}.${name}`;
        return (
          <FormRow title={`${localizationPath}.title`}>
            <InputHandler
              type={settings.inputType}
              name={name}
              editing={editing}
              formData={formData}
              options={settings.options}
              optionFormatter={(id: string | number): string => (fm ? fm(`${localizationPath}.opts.${id}`) : '')}
              placeholder={`${localizationPath}.placeholder`}
              dependentFieldsList={settings.dependedFieldsList}
            />
          </FormRow>
        );
      }}
    </DisconnectionContext.Consumer>
  );
};

const DisconnectionEditingElement = (): JSX.Element => (
  <DisconnectionContext.Consumer>
    {({ formData }): JSX.Element => {
      const document = formData?.document;
      const type = document?.type ?? '';

      const sideKeys: ISettingsSchema = { inputType: 'Radio', name: 'side', options: ['left', 'right'] };
      const otherInfoKeys: ISettingsSchema = { inputType: 'TextArea', name: 'otherInfo' };
      const locationKeys: ISettingsSchema = { inputType: 'Select', name: 'location' };

      const disconnectionSettings: { [fieldName: string]: Array<ISettingsSchema> } = {
        blockDisconnection: [
          {
            inputType: 'Select',
            name: 'block',
            options: [
              'temporalBackOfSkullDisconnection',
              'temporalDisconnection',
              'frontalDisconnection',
              'encephalocele',
              'other',
            ],
            dependedFieldsList: (): string[] => ['otherInfo'],
          },
          sideKeys,
        ],
        skullsotomy: [{ inputType: 'Select', name: 'scope', options: ['anterior', 'posterior', 'total'] }],
        hemispherectomy: [sideKeys],
        functionalPartialHemispherectomy: [{ ...locationKeys, options: ['anterior', 'posterior', 'other'] }, sideKeys],
        multipleSubpialTransection: [{ ...locationKeys, options: ['motor', 'sensory', 'vocal'] }, sideKeys],
        hypothalamicHamartomaDisconnection: [
          { inputType: 'Radio', name: 'disconnection', options: ['craniotomy', 'endoscopy'] },
        ],
        other: [otherInfoKeys],
      };

      const settings: Array<{ inputType: string; name: string; options?: Array<string> }> =
        path([type], disconnectionSettings) ?? [];

      const isOther = path([settings[0]?.name ?? ''], document) === 'other';
      if (isOther) {
        settings.push(otherInfoKeys);
      }

      return (
        <>
          {settings &&
            settings.map((s: any): JSX.Element => <ChooseInputHandler key={s.name} settings={s} type={type} />)}
        </>
      );
    }}
  </DisconnectionContext.Consumer>
);

const EditingElement = ({
  formData,
  editing,
  fm,
}: {
  formData: IFormData<TDisconnection>;
  editing: boolean;
  fm?: (id: string) => string;
}): JSX.Element => {
  const type = formData.document?.type;
  const locPath = 'surgicalTreatment.disconnection.disconnections';

  return (
    <React.Fragment>
      <FormRow title={`${locPath}.type.title`}>
        <InputHandler
          type="Select"
          name="type"
          editing={editing}
          formData={formData}
          options={[
            'blockDisconnection',
            'skullsotomy',
            'hemispherectomy',
            'functionalPartialHemispherectomy',
            'multipleSubpialTransection',
            'hypothalamicHamartomaDisconnection',
            'other',
          ]}
          optionFormatter={(id: string | number): string => optionFormatter(id, 'disconnections.type.opts', fm)}
          placeholder={`${locPath}.type.placeholder`}
          dependentFieldsList={(): string[] => ['otherInfo', 'block', 'scope', 'side', 'location']}
        />
      </FormRow>
      <DisconnectionContext.Provider value={{ formData: formData, editing: editing, fm: fm, locPath: locPath }}>
        {type && <DisconnectionEditingElement />}
      </DisconnectionContext.Provider>
    </React.Fragment>
  );
};

const Disconnection = ({ formData, editing, view, fm }: IFormContext): JSX.Element => {
  const editingDoc = !!editing && !view?.viewing;
  const { document, onChange } = formData as IFormData<ISurgicalTreatment>;

  return (
    <React.Fragment>
      <OperationContext.Consumer>
        {({ operationIndex }): JSX.Element => {
          const allOperations = document.operations?.map((o) => o) || [];
          const disconnection = allOperations?.[operationIndex ?? 0] as IDisconnection;
          const disconnections = disconnection.disconnections;

          const disconnectionOnChange = (values: TOnChangeValues): void => {
            const value = values['disconnections'];
            const newDisconnection = value;
            const changedOperation = { ...disconnection, disconnections: newDisconnection };
            allOperations[operationIndex ?? 0] = changedOperation;
            onChange && onChange({ operations: allOperations });
          };

          return (
            <EventStepper
              name="disconnections"
              formData={{
                document: { disconnections: disconnections },
                onChange: disconnectionOnChange,
              }}
              stepLabelText={(d: TDisconnection): string => stepLabelText(d, fm)}
              stepContent={(d: TDisconnection): JSX.Element => (
                <StepContentContext.Provider value={{ d: d, fm: fm }}>
                  <DisconnectionContent />
                </StepContentContext.Provider>
              )}
              addNewTextHeader="surgicalTreatment.disconnection.disconnections.title"
              addNewTextButton="surgicalTreatment.disconnection.disconnections.add"
              previousEventsTextHeader=""
              editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
                return (
                  <React.Fragment>
                    {!isNil(operationIndex) ? (
                      <EditingElement
                        formData={
                          { document: disconnections?.[index], onChange: onChange } as {
                            document: TDisconnection;
                            onChange: IFormData['onChange'];
                          }
                        }
                        editing={editingDoc}
                        fm={fm}
                      />
                    ) : undefined}
                  </React.Fragment>
                );
              }}
            />
          );
        }}
      </OperationContext.Consumer>
    </React.Fragment>
  );
};

export default withFormContext(Disconnection);
