import * as React from 'react';
import { IRegimenContext } from '..';
import FormRow from '../../../../../../../components/FormRow';
import InputHandler from '../../../../../../../components/InputHandler';
import { StrengthSelect } from '../components/StrengthSelect';
import { otherOnChange, strengthsArray, strengthsOnChange } from '../utils';
import { path } from 'Utility/ramdaReplacement';

const OtherRegimen = ({ formData, editIndex, packages }: IRegimenContext): JSX.Element => {
  const regimen = formData.document?.regimen?.[editIndex] as IRegimenBasics & IRegimenOther;
  const allRegimen = formData.document?.regimen as Array<Regimen>;
  const availableStrengths = strengthsArray(packages);
  const strengths = regimen.strengths || [];

  return (
    <React.Fragment>
      <StrengthSelect
        formData={formData}
        availableStrengths={availableStrengths}
        strengthsOnChange={strengthsOnChange(formData.onChange, availableStrengths, editIndex, regimen, allRegimen)}
        strengths={strengths}
        editIndex={editIndex}
      />
      <FormRow title="medication.regimenOtherInstructions" headerWidth={3}>
        <InputHandler
          type="TextArea"
          name="instructions"
          editing={true}
          placeholder={'medication.regimenOtherInstructions'}
          width={19}
          formData={{
            onChange: otherOnChange(formData.onChange, editIndex, regimen, allRegimen),
            document: { instructions: path(['document', 'regimen', editIndex, 'instructions'], formData) || '' },
          }}
        />
      </FormRow>
    </React.Fragment>
  );
};

export default OtherRegimen;
