import * as React from 'react';
import { styled } from '@mui/system';
import CollapseElem from '../../../../components/Collapse';
import { fm } from 'Components/FormatMessage';
import HistoryRow from '../../../../components/HistoryRow';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowSubHeader from '../../../../components/HistoryRowSubHeader';
import colors from '../../../../config/theme/colors';
import { formatPartialDate } from 'neuro-utils';
import { EventListing, Generator, MakeHeaderBar, Setting } from './components';
import { getLatestGenerator } from '../../utils';
import { StyledTitleValueRow } from '../../components';
import { Container, Item } from '../../../../components/Grid';
import { generatorsWithAutoStimNightAndScheduledSettings, generatorsWithAutoStimSettings } from '../config';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { path } from 'Utility/ramdaReplacement';

const StyledSection = styled('div')({
  marginBottom: '4rem',
});

const StyledSubSubHeader = styled('div')({
  fontSize: '2rem',
  color: colors.primary,
  fontWeight: 600,
  margin: '3rem 0 1rem 0',
  '* > &:first-of-type': {
    margin: '0 0 1rem 0',
  },
});

// Set anchor for scroll and start editing
const startEditAndSetAnchor = (startEdit: (e: any) => void, setAnchor: () => void) => (): void => {
  setAnchor();
  startEdit(undefined);
};

// Remove anchor and call startEdit
const startEditAndRemoveAnchor =
  (startEdit: IOwnProps['startEdit'], setAnchor: (n?: string) => () => void) =>
  (d: IVNS) =>
  (e: React.MouseEvent): void => {
    setAnchor(undefined)();
    startEdit(d)(e);
  };

const EditButton = ({ startEdit, setAnchor, d, anchorName }: IEditButton): JSX.Element => (
  <Container justifyContent="flex-end">
    <DocumentCreationButton
      name="vns"
      document={d}
      text="general.edit"
      onClick={startEditAndSetAnchor(startEdit(d), setAnchor(anchorName))}
      width={13}
      height={3}
      fontSize={16}
    />
    <Item xs={2} />
  </Container>
);

const StyledSubHeader = ({ title, startEdit, setAnchor, d, anchorName }: IStyledSubHeader): JSX.Element => (
  <Container>
    <Item xs={9}>
      <HistoryRowSubHeader header={title} />
    </Item>
    <Item xs={3}>
      <EditButton startEdit={startEdit} setAnchor={setAnchor} d={d} anchorName={anchorName} />
    </Item>
  </Container>
);

const VNSHistoryRowData = ({ d, startEdit, setAnchor }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <HistoryRow
      headerText={
        d.hasEnded?.[0] !== true ? (
          d.date && d.status !== 'suspended' ? (
            fm('dbs.startingDate', { date: formatPartialDate(d.date) })
          ) : d.date ? (
            <div>
              {fm('dbs.startingDate', { date: formatPartialDate(d.date) })}
              {' - '}
              <span style={{ fontWeight: 600, textTransform: 'uppercase' }}>{fm('dbs.opts.suspended')}</span>
            </div>
          ) : (
            ''
          )
        ) : (
          d.date && `${formatPartialDate(d.date)} - ${d.endDate ? formatPartialDate(d.endDate) : ''}`
        )
      }
      rowButton={<HistoryRowControls document={d} startEdit={startEditAndRemoveAnchor(startEdit, setAnchor)} />}
      controlsMargin={false}
    >
      <React.Fragment>
        <StyledSection>
          <StyledTitleValueRow
            title={fm('vns.electrodeMountingLocation')}
            value={
              d.electrodeMountingLocation && fm(`vns.opts.electrodeMountingLocation.${d.electrodeMountingLocation}`)
            }
          />
          <StyledTitleValueRow
            title={fm('vns.leadType')}
            value={
              typeof d.leadType === 'string' &&
              ['unspecifiedLeadWithOneConnector', 'unspecifiedLeadWithTwoConnectors'].includes(d.leadType)
                ? fm(`vns.opts.leadType.${d.leadType}`)
                : d.leadType
            }
          />
          <StyledTitleValueRow title={fm('vns.leadSerialNo')} value={d.leadSerialNo} />
          <StyledTitleValueRow title={fm('vns.implantationSite')} value={d.implantationSite} />
        </StyledSection>
        <StyledSection>
          <StyledSubHeader
            title={fm('vns.generator')}
            startEdit={startEdit}
            setAnchor={setAnchor}
            d={d}
            anchorName="generators"
          />
          {Array.isArray(d.generators) && d.generators.length > 0 && (
            <React.Fragment>
              <Generator generator={d.generators[0]} />
              {d.generators.length > 1 && (
                <CollapseElem
                  localeIDs={{ showMessage: 'vns.showGenerators', hideMessage: 'vns.hideGenerators' }}
                  amount={d.generators.length - 1}
                >
                  <React.Fragment>
                    {d.generators.slice(1).map((generator, index) => (
                      <Generator key={index} generator={generator} />
                    ))}
                  </React.Fragment>
                </CollapseElem>
              )}
              {Array.isArray(d.magnetModeActivations) && d.magnetModeActivations.length > 0 && (
                <React.Fragment>
                  <StyledSubSubHeader>{fm('vns.magnetModeActivations')}</StyledSubSubHeader>
                  <EventListing events={d.magnetModeActivations} type="modeActivation" />
                </React.Fragment>
              )}
              {Array.isArray(d.autoStimModeActivations) &&
                d.autoStimModeActivations.length > 0 &&
                generatorsWithAutoStimNightAndScheduledSettings
                  .concat(generatorsWithAutoStimSettings)
                  .includes(getLatestGenerator(d)?.generator ?? '') && (
                  <React.Fragment>
                    <StyledSubSubHeader>{fm('vns.autoStimModeActivations')}</StyledSubSubHeader>
                    <EventListing events={d.autoStimModeActivations} type="modeActivation" />
                  </React.Fragment>
                )}
            </React.Fragment>
          )}
        </StyledSection>

        <StyledSection>
          <StyledSubHeader
            title={fm('vns.settings')}
            startEdit={startEdit}
            setAnchor={setAnchor}
            d={d}
            anchorName="settings"
          />
          {Array.isArray(d.settings) && d.settings.length > 0 && (
            <React.Fragment>
              <Setting setting={d.settings[0]} d={d} editIndex={0} />
              {d.settings.length > 1 && (
                <CollapseElem
                  localeIDs={{ showMessage: 'vns.showSettings', hideMessage: 'vns.hideSettings' }}
                  amount={d.settings.length - 1}
                >
                  <React.Fragment>
                    {d.settings.slice(1).map((setting, index) => (
                      <Setting key={index} setting={setting} d={d} editIndex={index + 1} />
                    ))}
                  </React.Fragment>
                </CollapseElem>
              )}
            </React.Fragment>
          )}
        </StyledSection>
        <StyledSection>
          <StyledSubHeader
            title={fm('vns.postoperativeComplications')}
            startEdit={startEdit}
            setAnchor={setAnchor}
            d={d}
            anchorName="postoperativeComplications"
          />
          {Array.isArray(d.postoperativeComplications) && d.postoperativeComplications.length > 0 && (
            <EventListing events={d.postoperativeComplications} type="postoperativeComplication" />
          )}
        </StyledSection>
        <StyledSection>
          <StyledSubHeader
            title={fm('vns.adverseEffectEvents')}
            startEdit={startEdit}
            setAnchor={setAnchor}
            d={d}
            anchorName="adverseEffectEvents"
          />
          {Array.isArray(d.adverseEffects) && d.adverseEffects.length > 0 && (
            <EventListing events={d.adverseEffects} type="adverseEffect" />
          )}
        </StyledSection>
        <StyledSection>
          <StyledSubHeader
            title={fm('vns.technicalIssues')}
            startEdit={startEdit}
            setAnchor={setAnchor}
            d={d}
            anchorName="technicalIssues"
          />
          {Array.isArray(d.technicalIssues) && d.technicalIssues.length > 0 && (
            <EventListing events={d.technicalIssues} type="technicalIssue" />
          )}
        </StyledSection>
        <StyledSection>
          <StyledSubHeader
            title={fm('vns.discontinuations')}
            startEdit={startEdit}
            setAnchor={setAnchor}
            d={d}
            anchorName="discontinuations"
          />
          {Array.isArray(d.discontinuations) && d.discontinuations.length > 0 && (
            <EventListing events={d.discontinuations} type="discontinuation" />
          )}
        </StyledSection>
        <StyledSection style={{ marginBottom: path(['hasEnded', 0], d) === true ? undefined : 0 }}>
          <StyledSubHeader
            title={fm('vns.ending')}
            startEdit={startEdit}
            setAnchor={setAnchor}
            d={d}
            anchorName="ending"
          />
          {path(['hasEnded', 0], d) === true && (
            <React.Fragment>
              <MakeHeaderBar date={d.endDate} />
              <StyledTitleValueRow
                title={fm('vns.endReason')}
                value={d.endReason && fm(`vns.opts.endReason.${d.endReason}`)}
              />
              <StyledTitleValueRow title={fm('vns.endAdditionalInformation')} value={d.endAdditionalInformation} />
              <StyledTitleValueRow
                title={fm('vns.removed')}
                value={d.removed && fm(`vns.opts.yesNoUnknown.${d.removed}`)}
              />
              {d.removed === 'yes' && (
                <React.Fragment>
                  <StyledTitleValueRow
                    title={fm('vns.removalDate')}
                    value={d.removalDate && formatPartialDate(d.removalDate)}
                  />
                  <StyledTitleValueRow
                    title={fm('vns.removalReason')}
                    value={d.removalReason && fm(`vns.opts.removalReason.${d.removalReason}`)}
                  />
                  <StyledTitleValueRow
                    title={fm('vns.removalType')}
                    value={d.removalType && fm(`vns.opts.removalType.${d.removalType}`)}
                  />
                  {d.removalType === 'generatorAndPartOfLeadRemoved' && (
                    <StyledTitleValueRow
                      title={fm('vns.lengthOfRemainingLead')}
                      value={d.lengthOfRemainingLead && fm(`vns.opts.lengthOfRemainingLead.${d.lengthOfRemainingLead}`)}
                    />
                  )}
                  <StyledTitleValueRow
                    title={fm('vns.removalAdditionalInformation')}
                    value={d.removalAdditionalInformation}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </StyledSection>
      </React.Fragment>
    </HistoryRow>
  </React.Fragment>
);

interface IEditButton {
  startEdit: IOwnProps['startEdit'];
  setAnchor: IOwnProps['setAnchor'];
  d: IOwnProps['d'];
  anchorName: string;
}

interface IStyledSubHeader extends IEditButton {
  title: string | JSX.Element;
  value?: any;
  condition?: boolean;
  id?: string;
}

interface IOwnProps {
  d: IVNS;
  startEdit: (document: IVNS) => (e: React.MouseEvent) => void;
  setAnchor: (anchor?: string) => () => void;
}

export default VNSHistoryRowData;
