import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import InputHandler from '../../../../../components/InputHandler';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import EventStepper from '../../../../../components/EventStepper';
import { fm } from 'Components/FormatMessage';

import { formatPartialDate, isPartialDate } from 'neuro-utils';

import { discontinuationHandler, getLatestDiscontinuation } from '../../../utils';
import PlatformConditional from '../../../../../components/PlatformConditional';
import { path } from 'Utility/ramdaReplacement';

const optionFormatter = (name: string | number): JSX.Element => <FormattedMessage id={'dbs.opts.' + name} />;

const DiscontinuationDetails = ({ discontinuation }: { discontinuation: IDBSDiscontinuationEvent }): JSX.Element => (
  <div style={{ marginBottom: '2rem' }}>
    <StepperHeaderValuePair
      header={<FormattedMessage id="dbs.discontinuationReasonShort" />}
      value={
        discontinuation.discontinuationReason && (
          <FormattedMessage id={`dbs.opts.${discontinuation.discontinuationReason}`} />
        )
      }
    />

    <StepperHeaderValuePair
      header={<FormattedMessage id="dbs.discontinuationDetails" />}
      value={discontinuation.discontinuationDetails}
    />

    <StepperHeaderValuePair
      header={<FormattedMessage id="dbs.discontinuationType" />}
      value={
        discontinuation.discontinuationType && (
          <FormattedMessage id={`dbs.opts.${discontinuation.discontinuationType}`} />
        )
      }
    />

    <StepperHeaderValuePair
      header={<FormattedMessage id="dbs.treatmentContinuationDate" />}
      value={discontinuation.endDate && formatPartialDate(discontinuation.endDate as PartialDate)}
    />
  </div>
);

const DiscontinuationEvents = ({ formData, disabled }: IOwnProps): JSX.Element => {
  const { onChange } = formData;
  const status = formData.document.status;
  const hasEnded = formData.document.hasEnded?.[0] === true;
  const discontinuations = formData.document.discontinuationEvents;
  const generators = formData.document.generators;

  React.useEffect(() => {
    if (onChange && discontinuations && Array.isArray(generators) && generators.length > 0) {
      discontinuationHandler(onChange, discontinuations, generators, status ?? '', hasEnded);
    }
  }, [onChange, discontinuations, generators, status, hasEnded]);

  const getButtonDisabledMessage = (): JSX.Element | undefined => {
    if (disabled) {
      return fm('dbs.addGeneratorFirst');
    }
    if (formData.document.status === 'ended') {
      return fm('dbs.discontinuationHoverMessageEnded');
    }
    if (formData.document.status === 'suspended') {
      const discontinuationType = path(
        ['discontinuationType'],
        getLatestDiscontinuation(formData.document.discontinuationEvents),
      );
      if (!discontinuationType) {
        return fm('dbs.discontinuationHoverMessageUndefined');
      }
      if (discontinuationType === 'generatorSwitchedOff') {
        return fm('dbs.discontinuationHoverMessageSwitchedOff');
      }
      return fm('dbs.discontinuationHoverMessageSuspended');
    }
    return undefined;
  };

  return (
    <EventStepper
      name="discontinuationEvents"
      formData={formData}
      stepLabelText={(d: IDBSDiscontinuationEvent): string => formatPartialDate(d.date)}
      stepContent={(d: IDBSDiscontinuationEvent): JSX.Element => <DiscontinuationDetails discontinuation={d} />}
      addNewTextHeader="dbs.newDiscontinuation"
      addNewTextButton="dbs.newDiscontinuation"
      buttonDisabled={disabled || ['suspended', 'ended'].includes(formData.document.status ?? '')}
      buttonDisabledMessage={getButtonDisabledMessage()}
      previousEventsTextHeader="dbs.discontinuations"
      editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
        <React.Fragment>
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="PartialDate"
              name="date"
              editing={true}
              dateDefault="now"
              dateHook={{
                dateHookFloor: formData?.document?.generators?.slice().reverse()[0]
                  ? formData.document?.generators?.slice().reverse()[0]?.generatorDate
                  : formData.document.date,
                dateHookCeiling: formData.document.endDate,
              }}
              isNotCancellable={true}
              formData={{
                onChange: onChange,
                document: {
                  date: formData.document.discontinuationEvents?.[index]?.date || '',
                },
              }}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <FormattedMessage id="dbs.discontinuationReasonShort" />
            <PlatformConditional platform="parkinson">
              <InputHandler
                type="Radio"
                name="discontinuationReason"
                editing={true}
                options={['infection', 'other']}
                optionFormatter={optionFormatter}
                formData={{
                  onChange: onChange,
                  document: {
                    discontinuationReason:
                      formData.document.discontinuationEvents?.[index]?.discontinuationReason || '',
                  },
                }}
              />
            </PlatformConditional>
            <PlatformConditional platform="epilepsy">
              <InputHandler
                type="Radio"
                name="discontinuationReason"
                editing={true}
                options={['technicalCause', 'infection', 'otherSideEffect', 'deteriorationOfSeizureSituation', 'other']}
                optionFormatter={optionFormatter}
                formData={{
                  onChange: onChange,
                  document: {
                    discontinuationReason:
                      formData.document.discontinuationEvents?.[index]?.discontinuationReason || '',
                  },
                }}
              />
            </PlatformConditional>

            <InputHandler // Use InputHandler here so that we can use placeholder with TextField
              type="TextArea"
              name="discontinuationDetails"
              editing={true}
              formData={{
                onChange: onChange,
                document: {
                  discontinuationDetails:
                    formData.document.discontinuationEvents?.[index]?.discontinuationDetails || '',
                },
              }}
              placeholder={'dbs.discontinuationDetails'}
            />
          </div>

          <div style={{ marginBottom: '2rem' }}>
            <FormattedMessage id="dbs.discontinuationType" />
            <InputHandler
              type="Radio"
              name="discontinuationType"
              editing={true}
              options={['generatorSwitchedOff', 'generatorRemoved', 'generatorAndLeadsRemoved']}
              optionFormatter={optionFormatter}
              formData={{
                onChange: onChange,
                document: {
                  discontinuationType: formData.document.discontinuationEvents?.[index]?.discontinuationType || '',
                  endDate: formData.document.discontinuationEvents?.[index]?.endDate,
                },
              }}
              dependentFieldsList={(value: TFieldValue): string[] => {
                if (
                  // generatorSwitchedOff changed to generatorRemoval --> endDate is dependent
                  (path(['discontinuationEvents', index, 'discontinuationType'], formData.document) ===
                    'generatorSwitchedOff' &&
                    ['generatorRemoved', 'generatorAndLeadsRemoved'].includes(
                      typeof value === 'string' ? value : '',
                    )) ||
                  // generatorRemoval changed to generatorSwitchedOff --> endDate is dependent
                  (['generatorRemoved', 'generatorAndLeadsRemoved'].includes(
                    path(['discontinuationEvents', index, 'discontinuationType'], formData.document) ?? '',
                  ) &&
                    value === 'generatorSwitchedOff')
                ) {
                  return ['endDate'];
                }
                return [];
              }}
              dependentFieldsRemovalWarning={true}
              dependentFieldsRemovalWarningText={fm('dbs.discontinuationMessageTypeChange')}
            />
          </div>
          {isPartialDate(path(['discontinuationEvents', index, 'endDate'], formData.document)) && (
            <div style={{ marginBottom: '2rem' }}>
              <FormattedMessage id="dbs.treatmentContinuationDate" />
              <InputHandler
                type="PartialDate"
                editing={
                  path(['discontinuationEvents', index, 'discontinuationType'], formData.document) ===
                  'generatorSwitchedOff'
                }
                name="endDate"
                formData={{
                  onChange,
                  document: {
                    endDate: formData.document.discontinuationEvents?.[index]?.endDate || '',
                  },
                }}
                dateHook={{
                  dateHookFloor: formData.document.discontinuationEvents?.[index]?.date,
                }}
                isNotCancellable={true}
              />
            </div>
          )}
        </React.Fragment>
      )}
    />
  );
};

interface IOwnProps {
  formData: IFormData<IDBS>;
  disabled: boolean;
}
export default DiscontinuationEvents;
