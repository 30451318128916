import * as React from 'react';
import { exists } from 'neuro-utils';
import normalValues from '../Form/normalValues';
import { fm } from 'Components/FormatMessage';
import { isEmpty, omit } from 'Utility/ramdaReplacement';

export const resolvePath = (path: string | string[], obj: any): any => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

export const rootOf = (path: string): string => {
  return path.split('.')[0];
};

export const endOf = (path: string): string => {
  return path.split('.')[path.split('.').length - 1];
};

export const filter = (values: { [key: string]: any }): { [key: string]: any } =>
  omit(['score', 'calculatedScore'], values);

export const optionFormatter = (n: string | number | boolean): JSX.Element =>
  typeof n === 'string' ? fm(`neurologicalStatusAndEDSS.opts.${n}`) : <span>{n}</span>;

export const mapUndefinedToNotDone = (formData: IFormData<INeurologicalStatusAndEDSS>): void => {
  const defaultValues = Object.assign({}, normalValues);
  defaultValues.visualAcuity.visionCorrection = 'unknown';

  const fillRowValue = (row: string): string | undefined => {
    return row.substring(0, 32) === 'ableToLiftOnlyOneLegAtATimeGrade' || row.substring(0, 12) === 'visualAcuity'
      ? undefined
      : row === 'visionCorrection'
        ? 'unknown'
        : 'notDone';
  };

  //const d = Object.assign({}, formData.document) as { [key: string]: any };
  const d = JSON.parse(JSON.stringify(formData.document));

  Object.keys(defaultValues)
    .slice(1)
    .forEach((stepSection) => {
      if (!d[stepSection]) {
        d[stepSection] = {};
      }
      Object.keys(defaultValues[stepSection])
        .filter((key) => key !== 'calculatedScore')
        .forEach((row) => {
          if (!exists(d[stepSection][row]) || d[stepSection][row] === null) {
            d[stepSection][row] = {};
          }
          if (typeof defaultValues[stepSection][row] === 'object') {
            Object.keys(defaultValues[stepSection][row]).forEach((subRow) => {
              if (!exists(d[stepSection][row][subRow]) || d[stepSection][row][subRow] === null) {
                Object.assign(d[stepSection][row], {
                  [subRow]: fillRowValue(subRow),
                });
              }
            });
          } else if (isEmpty(d[stepSection][row])) {
            Object.assign(d[stepSection], {
              [row]: fillRowValue(row),
            });
          }
        });
      formData.onChange?.({
        [stepSection]: d[stepSection],
      });
    });
};
